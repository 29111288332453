import React from 'react'
import './carousel.css'
import { Link } from 'react-router-dom';

function carousel({imagePath, title, description, eventID}) {
  return (
            
            <div class='slide-content'>
                <div class='card-wrapper'>
                    <div class='card-carousel'>
                        <div class='image-content'>
                            <span class='overlay'></span> 
                            <div class='card-image'>
                                <img src={imagePath} class="card-img"/>
                            </div>
                            <div class="card-content">
                                <h2 class="name">{title}</h2>
                                <p class="description">{description} </p>
                            </div>
                            <Link to={`/result/${eventID}/intro`}>
                                <button className="carousel-btn">View More</button>
                            </Link>
                        </div>
                    </div>
                </div>
    </div>

  )
}

export default carousel