import { combineReducers } from "redux";
import service from "./service";
import user from "./user";
import event from "./event";
import message from "./message";
import { cart } from './cart';
export default combineReducers({
  service,
  user,
  event,
  message,
  cart
});
