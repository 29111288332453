/*
This file register an user as merchant

Created by Mingke Deng, and Hans Wang
Last Modified: 23/09/2022
*/
import React, { useState, useEffect } from "react";
import axios from "axios";
import "./register.css";
import { CheckOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { usePromiseTracker } from "react-promise-tracker";
import { trackPromise } from "react-promise-tracker";
import PasswordStrengthBar from "react-password-strength-bar";
import { ThreeDots } from "react-loader-spinner";
import { Modal, Button, message } from "antd";
import XMLParser from "react-xml-parser";
import NavBar from "../Home/homes/Navbar";
import Footer from "../../components/footer/Footer";

function Merchant() {
  const [details, setDetails] = useState({
    userName: "",
    email: "",
    password: "",
    accessNumber: 3,
    abn: 0,
  });
  const history = useNavigate();
  const [abnCheck, setAbnCheck] = useState([]);
  const [valid, setValid] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = async () => {
    getLookUp().then((data) => {
      setAbnCheck(data.children[1]);
      if (data.children[1].children[2].name !== "exception") {
        setValid(true);
        console.log("valid");
      } else {
        console.log("invalid");
      }
    });

    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const LoadingIndicator = () => {
    const { promiseInProgress } = usePromiseTracker();
    return (
      promiseInProgress && (
        <div style={{ marginLeft: "14vw" }}>
          <ThreeDots color="#00BFFF" height={80} width={80} />
        </div>
      )
    );
  };
  const submitHandler = (e) => {
    e.preventDefault();
    if (valid === true) {
      trackPromise(
        axios
          .post("https://eventeasyapi.com/api/user/create", details)
          .then((response) => {
            console.log(response);
            sessionStorage.setItem("id", response.data.userId);
            sessionStorage.setItem("username", response.data.userName);
            sessionStorage.setItem("access", response.data.accessNumber);

            Modal.confirm({
              //a pop up window
              icon: <CheckOutlined />,
              title: "Congradulations",
              content: "Register successed, Welcome !!!",
              onOk: () => {
                history("/");
              },
            });
          })
          .catch((error) => {
            message.error("username or email already exist");
          })
      );
    } else {
      window.alert("Please register with a valid ABN");
    }
  };

  const getLookUp = async () => {
    const { data } = await axios.get(
      `https://abr.business.gov.au/ABRXMLSearch/AbrXmlSearch.asmx/SearchByABNv201408?searchString=${details.abn}&includeHistoricalDetails=n&authenticationGuid=fbb9c7c6-f657-4411-ae5c-3b5eb36a9fcd`,
      {
        headers: {
          Host: "abr.business.gov.au",
          "Content-Type": "text/xml",
        },
      }
    );

    var xml = new XMLParser().parseFromString(data);
    console.log("xml", xml);
    return xml;
    //https://abr.business.gov.au/ABRXMLSearch/AbrXmlSearch.asmx/SearchByABNv201408?searchString=74599608295&includeHistoricalDetails=n&authenticationGuid=fbb9c7c6-f657-4411-ae5c-3b5eb36a9fcd
  };

  return (
    <div>
      <NavBar />
      <div class="register-base">
        <h3 class="login-title">Registration</h3>
        <div class="login-card-contatiner">
          <div class="left-image"></div>
          <form onSubmit={submitHandler} class="form-conatiner">
            <h4 class="mb-3 login-title-2 text-center">Welcome</h4>
            <div class="d-flex justify-content-center">
              <div class="user-icon">
                <div>
                  <i class="fas fa-briefcase"></i>
                </div>
                <div class="user-name">Merchant</div>
              </div>
            </div>
            <div class="input-group mb-4">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fas fa-user fa-lg icon-gray"></i>
                </span>
              </div>
              <input
                name="userName"
                type="text"
                placeholder="username"
                aria-label="username"
                class="form-control"
                value={details.userName}
                onChange={(e) =>
                  setDetails({ ...details, userName: e.target.value })
                }
                required
              />
            </div>
            <div class="input-group mb-4">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fas fa-envelope fa-lg icon-gray"></i>
                </span>
              </div>
              <input
                name="email"
                type="email"
                placeholder="Email"
                class="form-control"
                aria-label="Email"
                value={details.email}
                onChange={(e) =>
                  setDetails({ ...details, email: e.target.value })
                }
                required
              />
            </div>
            <div class="input-group mb-4">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fas fa-key fa-lg icon-gray"></i>
                </span>
              </div>
              <input
                type="password"
                name="password"
                placeholder="Password"
                class="form-control"
                aria-label="password"
                value={details.password}
                onChange={(e) =>
                  setDetails({ ...details, password: e.target.value })
                }
                required
              />
            </div>
            <div class="input-group my-2">
              <div>
                <PasswordStrengthBar
                  password={details.password}
                  style={{ width: "120px", marginLeft: "1vw" }}
                />
              </div>
            </div>
            <div class="input-group mb-4">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fas fa-lock fa-lg icon-gray"></i>
                </span>
              </div>
              <input
                type="password"
                class="form-control"
                placeholder="Confirm Password"
                aria-label="Confirm Password"
              ></input>
            </div>

            <Button type="primary" onClick={showModal}>
              Check ABN
            </Button>
            <Modal
              title="ABN LookUp"
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
            >
              {abnCheck !== null ? (
                abnCheck.children ? (
                  abnCheck.children[2].name !== "exception" ? (
                    <div>
                      <div>
                        <p>
                          {abnCheck.children[3].children[6].children[0].name}:
                        </p>
                      </div>
                      <div>
                        <p>
                          {abnCheck.children[3].children[6].children[0].value}
                        </p>
                      </div>
                      <div>
                        <p>
                          ABN Status:{" "}
                          {abnCheck.children[3].children[1].children[1]
                            .value === "Y"
                            ? "Valid"
                            : "Invalid"}
                        </p>
                      </div>

                      <div>
                        <p>
                          {abnCheck.children[3].children[4].children[1].name}:
                        </p>
                      </div>
                      <div>
                        <p>
                          {abnCheck.children[3].children[4].children[1].value}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div>
                        <p>{abnCheck.children[2].name}</p>
                      </div>
                      <div>
                        <p>{abnCheck.children[2].children[0].value}</p>
                      </div>
                    </div>
                  )
                ) : (
                  <div>a system false</div>
                )
              ) : (
                <div>
                  <p>System error please recheck</p>
                </div>
              )}
            </Modal>

            <div class="input-group mt-3 mb-4">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fas fa-file fa-lg icon-gray"></i>
                </span>
              </div>
              <input
                name="abn"
                class="form-control"
                type="nubmer"
                placeholder="ABN (Digits only, no spaces.)"
                aria-label="ABN (Digits only, no spaces.)"
                value={details.abn}
                onChange={(e) => {
                  setDetails({ ...details, abn: e.target.value });
                  setValid(false);
                }}
                required
              />
            </div>

            <div class="mb-3 d-flex align-items-center justify-content-between">
              <div>
                <input
                  type="checkbox"
                  class="form-check-input"
                  name="rememberMe"
                  value="rememberMe"
                ></input>
                <label
                  class="sub-text-link-gray form-check-label"
                  for="rememberMe"
                >
                  Remember Me
                </label>
              </div>
              <button type="submit" class="btn-primary primary-green-btn">
                Register
              </button>
            </div>
            <div class="d-flex align-items-center justify-content-between mb-3">
              <span class="sub-text-link-gray">
                Have an account? <Link to="/login-merchant">Login In</Link>
              </span>
            </div>
          </form>
        </div>
        <LoadingIndicator />
      </div>
      <Footer />
    </div>
  );
}

export default Merchant;

//a backup scheme make sure data safe
// const useBeforeRender = (callback, deps) => {
//   const [isRun, setIsRun] = useState(false);

//   if (!isRun) {
//       callback();
//       setIsRun(true);
//   }

//   useEffect(() => () => setIsRun(false), deps);
// };
// useBeforeRender(() => {
// getLookUp().then(
// data=>{
//   setAbnCheck(data.children[1])
// })
// }, []);
