// /*
// This is the file that display all messages
// between the site user and a merchant
// */
import React, { useState, useEffect } from "react";
import { Button, Card, Table, Space, Modal, message } from "antd";
import { PlusOutlined, ReadOutlined } from "@ant-design/icons";
import axios from "axios";
import { usePromiseTracker } from "react-promise-tracker";
import { Puff } from "react-loader-spinner";
import { trackPromise } from "react-promise-tracker";
import { useNavigate } from "react-router-dom";

function ApprovedBookingUser() {
  const [dataSource, setDataSource] = useState([]);
  const { promiseInProgress } = usePromiseTracker();
  const navigate = useNavigate();


  const onGetServices = () => {
    trackPromise(
      axios
        .get(`https://eventeasyapi.com/api/BookingManagement/GetAcceptedBooking?id=${sessionStorage.getItem("id")}`)
        .then((data) => {
          console.log(data.data.$values);
          setDataSource(data.data.$values);
        })
    );
  };

  useEffect(() => {
    onGetServices();
  }, []); // Empty dependency array to mimic componentDidMount

  const onPurchase = (record) => {
    navigate(`/checkout/${record.bookingId}`);
  };
  const getTableProps = () => ({
    pagination: {
      pageSize: 7,
      showTotal: () => `Total: ${dataSource.length} messages`,
    },
    columns: [
      {
        title: "Booking ID",
        dataIndex: "bookingId",
        sorter: (a, b) => a.messageDate.localeCompare(b.messageDate),
      },
      {
        title: "UserID",
        dataIndex: "userID",
      },
      {
        title: "Booking Name",
        dataIndex: "bookingName",
      },
      {
        title: "Start Date",
        dataIndex: "startTime",
      },
      {
        title: "End Date",
        dataIndex: "endTime",
      },
      {
        title: "Price",
        dataIndex: "price",
      },
      {
        title: "Guests",
        dataIndex: "guests",
      },
      {
        title: "Comment",
        dataIndex: "comment",
      },
      {
        title: "Decline Reason",
        dataIndex: "declineReason",
      },
      {
        title: "Status",
        dataIndex: "status",
      },
      {
        title: "Operate",
        render: (record) => (
          <Space>
            {record.bookingId === 13 ? (
              <>
                <span>Paid</span>
                <a onClick={() => onPurchase(record)}></a>
              </>
            ) : (
              <a onClick={() => onPurchase(record)}>Purchase</a>
            )}
          </Space>
        ),
      },
    ],
    dataSource: dataSource || [],
  });
  

  return (
    <div>
      <div className="m-operate">{/* <Button type='primary' icon={<PlusOutlined/>} onClick={this.onCreate()}>ADD</Button> */}</div>
      <Card>
        {/* component */}
        <Table {...getTableProps()} />
        {promiseInProgress && (
          <div style={{ marginLeft: "14vw" }}>
            <Puff color="#00BFFF" height={80} width={800} />
          </div>
        )}
      </Card>
    </div>
  );
}

export default ApprovedBookingUser;
