/*
This file shows the Home page

Created by Mingke Deng, and Hans Wang
Last Modified: 23/09/2022
*/
import React from 'react';
import './home.css'
import Navbar from './homes/Navbar';
import HeroSection from './homes/HeroSection'

//new stuff
import Image_Feature_card from '../../components/image_feature_card/Image_Feature_Card'
import weddingImage from "../../images/wedding.jpg";
import birthdayImage from "../../images/birthday.jpg";
import conferenceImage from "../../images/conference.jpg";

import NewFooter from '../../components/footer/Footer'

import '../../components/navbar/Navbar.css'

import Geolocation from '../../components/geo-location/GeoLocation';

function Home () {
    return(
        <div className='Home'>
            <Navbar/>
            <Geolocation/>
            {/* <NewNavbar/> */}
            <HeroSection />
            {/* <Card /> */}
            <h1 id="featured-events"> Featured Events</h1>
            <Image_Feature_card body={"Book the most romantic wedding for your partner at a gorgeous venue."} imageURL={weddingImage} title={"Wedding"}/>
            <Image_Feature_card body={"Plan the biggest surprise for your kids birthday!"} imageURL={birthdayImage} title={"Birthday"}/>
            <Image_Feature_card body={"Organise a professional venue for your coporate conference"} imageURL={conferenceImage} title={"Conference"}/>
            <NewFooter/>
            {/* <Footer /> */}
        </div>
    )
}

export default Home;