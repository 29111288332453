/*
This file shows the search result list. Although named venue.jsx 

Created by Mingke Deng, and Hans Wang
Last Modified: 23/09/2022
*/
import React, { useState, useEffect } from "react";
import Footer from "../../components/footer/Footer";
import { Radio } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "./Venue2.css";
import Navbar from "../Home/homes/Navbar";
import { stringify } from "rc-field-form/es/useWatch";
import { usePromiseTracker } from "react-promise-tracker";
import { Puff } from "react-loader-spinner";
import { trackPromise } from "react-promise-tracker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortAlphaDown, faSortAlphaUp } from "@fortawesome/free-solid-svg-icons";

import Event_Listing_Card from "../../components/event-listing-card/Event_Listing_Card";
import HeroSection2 from "../../pages/Home/homes/HeroSection2";
import Geolocation from "../../components/geo-location/GeoLocation";

export default function Event() {
  const [city, setCity] = useState();
  const [venueList, setVenueList] = useState([]);
  const [resultList, setResultList] = useState([]);
  const serType = [
    "Venue",
    "Food",
    "Beverage",
    "Entertainment",
    "Florist",
    "Photographer",
    "ALL",
  ];
  const areType = [
    "All",
    "Hobart",
    "SandyBay",
    "Kingston",
    "NewTown",
    "South Hobart",
    "North Hobart",
    "Dynnyrne",
  ];
  const bgtType = ["All", "$1,000", "$2,000", "$5,000", "$10,000", "10,000+"];
  const [curType, setCurType] = useState("ALL");
  const [curAre, setCurAre] = useState("All");
  const [curBgt, setCurBgt] = useState("All");
  const { type, date, guest, budget, area, stand, seat } = useParams();
  var number_of_results_found = 0;
  var usedFunc = false;

  const getAll = async () => {
    return new Promise((resolve, reject) => {
      axios
        .get("https://eventeasyapi.com/api/services/getEnableservices")
        .then((response) => {
          const data = response.data;
          setVenueList(data.$values); // store all venues
          console.log(venueList);
          setResultList(data.$values);
          resolve(); // Resolve the promise when API data is retrieved
        })
        .catch((error) => {
          reject(error); // Reject the promise if there's an error
        });
    });
  };

  console.log(venueList);
  const LoadingIndicator = () => {
    const { promiseInProgress } = usePromiseTracker();
    return (
      promiseInProgress && (
        <div style={{ marginLeft: "14vw" }}>
          <Puff color="#00BFFF" height={80} width={800} />
        </div>
      )
    );
  };

  const filterResult = (type, change) => {
    console.log(type, change);
    var venues = venueList.slice();
    var ind = 0;
    console.log("venues", venues);
    if (type === 0) {
      // change type is service
      switch (change) {
        case serType[0]:
          ind = 0;
          break;
        case serType[1]:
          ind = 1;
          break;
        case serType[2]:
          ind = 2;
          break;
        case serType[3]:
          ind = 3;
          break;
        case serType[4]:
          ind = 4;
          break;
        case serType[5]:
          ind = 5;
          break;
        case serType[6]:
          ind = 6;
          break;
        default:
          ind = 0;
          break;
      }
      console.log(curType, curAre, curBgt);
      venues = changeType(venues, ind);
      venues = changeArea(venues, curAre);
      venues = changeBudget(venues, curBgt);
    }
    if (type === 1) {
      // change type is area/suburbs
      //setCurAre(change);
      console.log("suburb", curAre);
      venues = changeType(venues, ind);
      venues = changeArea(venues, change);
      venues = changeBudget(venues, curBgt);
    }
    if (type === 2) {
      // change type is budget
      //setCurBgt(change);
      console.log("budget", curBgt);
      venues = changeType(venues, ind);
      venues = changeArea(venues, curAre);
      venues = changeBudget(venues, change);
    }

    return venues;
  };

  const changeType = (venues, type) => {
    if (type !== 6) {
      var newVenues = [];
      if (venues !== null) {
        venues.forEach((ele) => {
          if (ele.serviceType === type) {
            newVenues.push(ele);
          }
        });
      }
    } else {
      return venueList;
    }
    return newVenues;
  };

  const changeArea = (venues, area) => {
    //setCurAre(area);
    if (area !== areType[0]) {
      //if user select any area
      // console.log('curAre',area);
      // console.log('resultList',resultList);
      var newVenues = [];
      if (venues != null) {
        venues.forEach((ele) => {
          var location = stringify(ele.serviceLocation).toLowerCase();
          //console.log(location);
          if (location.includes(area.toLowerCase())) {
            //console.log("keep");
            newVenues.push(ele);
          }
        });
      }
      return newVenues;
    } else {
      //console.log(venueList);
      return venues;
    }
  };

  const changeBudget = (venues, bgt) => {
    //setCurBgt(bgt);
    var newBudget = 0;
    switch (bgt) {
      case bgtType[0]:
        return venues;

      case bgtType[1]:
        newBudget = 1000;

        break;
      case bgtType[2]:
        newBudget = 2000;

        break;
      case bgtType[3]:
        newBudget = 5000;

        break;
      case bgtType[4]:
        newBudget = 10000;

        break;
      case bgtType[5]:
        newBudget = 999999999999999;

        break;
      default:
        newBudget = budget;
        break;
    }

    // if (budget != areType[0]) { //if user select any area
    //console.log('curbgt', newBudget);
    //console.log('resultList', resultList);
    var newVenues = [];
    //venues = venues.filter(venue => venue.price <= newBudget);
    if (venues !== null) {
      venues.forEach((ele) => {
        var price = parseInt(ele.price);
        if (price <= newBudget) {
          //console.log("keep");
          newVenues.push(ele);
        }
      });
    }

    return newVenues;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getAll(); // Wait for getAll to complete and resolve the promise
        console.log(type, date, guest, budget, area, stand, seat);
      } catch (error) {
        // Handle any errors
      }
    };

    fetchData();
  }, []);

  //get city using API from latitude and longitude
  const getCurrentLocation = () => {
    //
    const filteredValue = sessionStorage.getItem("filtered");
    console.log(`filtered value is ${filteredValue}`);
    if (filteredValue == null) {
      console.log("inside function is running");
      axios
        .get(
          `https://nominatim.openstreetmap.org/reverse?format=json&lat=${sessionStorage.getItem(
            "Lat"
          )}&lon=${sessionStorage.getItem("Long")}`
        )
        .then((response) => {
          console.log("geolocation data");
          console.log(response.data);
          console.log(response.data.address.city);
          setCity(response.data.address.city);
          sessionStorage.setItem("city", response.data.address.city);

          //filter selection
          const areaValue = sessionStorage.getItem("area");

          //the area is set through the settings in the navbar then prioritise that filter instead of geolocation
          // if (areaValue !== null && areaValue !== undefined) {
          //   const hasCity = areType.includes(areaValue);
          //   console.log(`session area is ${areaValue}`);

          //   if (hasCity) {
          //     console.log(`NOW filtering area for ${areaValue} `);
          //     setResultList(filterResult(1, areaValue));
          //   } else {
          //     console.log("invalid city selected");
          //   }
          // } else if (areType.includes(response.data.address.city)) {
          //   const hasCity = areType.includes(response.data.address.city);
          //   if (hasCity) {
          //     console.log(
          //       `NOW FILTERING city for ${response.data.address.city}`
          //     );
          //     setResultList(filterResult(1, response.data.address.city));
          //   } else {
          //     console.log("invalid city");
          //   }
          // }
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response.status);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
      sessionStorage.setItem("filtered", "1");
    } else {
      //sessionStorage.setItem('filtered', '1')
    }
  };

  /*
  SORTING
  */
  const [sortEnabled, setSortEnabled] = useState(false);
  const [sortDirection, setSortDirection] = useState('asc');
  const handleSortToggle = () => {
    if (sortEnabled) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortEnabled(true);
    }
  };
  
  const sortIcon = sortEnabled ? (
    sortDirection === 'asc' ? (
      <FontAwesomeIcon icon={faSortAlphaUp} />
    ) : (
      <FontAwesomeIcon icon={faSortAlphaDown} />
    )
  ) : <FontAwesomeIcon icon={faSortAlphaUp}/>;
  
  const filteredList = resultList.filter(
    (ele) => ele.price <= budget && Math.max(ele.guestAmount, ele.seated, ele.standing) >= guest
  );
  
  const sortedList = sortEnabled
    ? filteredList.sort((a, b) => {
        if (sortDirection === 'asc') {
          return a.serviceName.localeCompare(b.serviceName);
        } else {
          return b.serviceName.localeCompare(a.serviceName);
        }
      })
    : filteredList;

  console.log("resultlist", resultList);
  //console.log("venues", venues)
  return (
    <div>
      {console.log("start rendering")}
      <Navbar />
      <Geolocation />
      {/* <p>Long {sessionStorage.getItem("Long")}</p>
            <p>Lat{sessionStorage.getItem("Lat")}</p> */}
      {getCurrentLocation()}
      {/* <p>The city is {city}</p> */}
      <h1 id="venue-title">Find an Event</h1>

      {resultList.length != 0 ? ( //is there any data remains
        resultList.map((ele, index) => {
          if (
            ele.price <= budget &&
            Math.max(ele.guestAmount, ele.seated, ele.standing) >= guest
          ) {
            number_of_results_found += 1;
          }
        })
      ) : (
        <p>0 Results Found</p>
      )}
      <p id="num-results-found">{number_of_results_found} Results Found</p>

      {/*
                TYPE SELECTION FILTER 
                */}

      <div class="select-filter">
      
        <div id="filter-title">
          <p>Type</p>
          <select
            class="select"
            value={curType}
            onChange={(e) => {
              setCurType(e.target.value);
              setResultList(filterResult(0, e.target.value));
            }}
          >
            {serType.map((ele, index) => {
              return (
                <option value={ele} key={index}>
                  {ele}
                </option>
              );
            })}
          </select>
        </div>

        <div id="filter-title">
          <p>Area</p>
          <select
            value={curAre}
            onChange={(e) => {
              setCurAre(e.target.value);
              setResultList(filterResult(1, e.target.value));
            }}
          >
            {areType.map((ele, index) => {
              return (
                <option value={ele} key={index}>
                  {ele}
                </option>
              );
            })}
          </select>
        </div>

        <div id="filter-title">
          <p>Budget</p>
          <select
            value={curBgt}
            onChange={(e) => {
              setCurBgt(e.target.value);
              setResultList(filterResult(2, e.target.value));
            }}
          >
            {bgtType.map((ele, index) => (
              <option value={ele} key={index}>
                {ele}
              </option>
            ))}
          </select>
        </div>
        <button id="filter-sort" onClick={handleSortToggle}>
          {sortIcon}
        </button>

      </div>
      
  
      <div className="events">
      
        {sortedList.length !== 0 ? ( //is there any data remains
          sortedList
          
          .map((ele, index) => {
            if (
              ele.price <= budget &&
              Math.max(ele.guestAmount, ele.seated, ele.standing) >= guest
            ) {
              number_of_results_found += 1;
              console.log(number_of_results_found);
              return (
                <Event_Listing_Card
                  title={ele.serviceName}
                  type="conference"
                  stand={ele.standing}
                  seat={ele.seated}
                  rating={ele.rate == null || ele.rate == 0 ? "N/A" : ele.rate}
                  description={ele.description}
                  imagePath={ele.imagePath}
                  services_id={ele.servicesId}
                  price={ele.price}
                  merchantId={ele.merchantId}
                  eventId={ele.servicesId}
                />
              );
            }
          })
        ) : (
          <li className="eve-row">{"No Relavent Result ..."}</li>
        )}
      </div>
      <LoadingIndicator />
      <Footer />
    </div>
  );
}
