import React,{useState} from "react";
import "./ResetPassword.css";
import Navbar from "../Home/homes/Navbar";
import Footer from "../../components/footer/Footer";
import { trackPromise } from "react-promise-tracker";
import axios from "axios";
import { message } from 'antd'
import { useEffect } from 'react';
import PasswordStrengthBar from "react-password-strength-bar";
import PasswordChecklist from "react-password-checklist"



/*
    RESET PASSWORD TESTING DETAILS
        user: userpass@gmail.com
        pass: reset or reset1

    AUTOMATED EMAIL DETAILS
        email: eventeasy@outlook.com.au
        password: EventReset
*/

function ResetPassword(){
    const queryParameters = new URLSearchParams(window.location.search)
    const urlToken = queryParameters.get("token")
      const [password, setPassword] = useState ({
        password: '',
    })


    const handlePasswordChange = (event) => {
        setPassword(event.target.value); 
        console.log(event.target.value); 
      
    }

    const verifyToken = async () => {
        
        await axios.put(`https://eventeasyapi.com/api/User/UpdatePasswordByToken/${urlToken}`, {"password":password})
        .then(async (data) => {
            console.log(data); 
            console.log("success");
        }).catch(err => {
            console.log(err)
          })
    }

    
    return(
        <div >
            <Navbar/>
            <div id="resetPasswordContainer"> 

                <p>Token: {urlToken}</p>
                <div id="password-input">               
                    <input
                    type="text"
                    placeholder="Your new password"
                    onChange={handlePasswordChange}   
                    required/> 
                    <button
                    onClick={verifyToken}
                    >Update</button>
                    <PasswordChecklist
                    rules={["minLength","specialChar","number","capital"]}
                    minLength={6}
                    value={password}
                    onChange={(isValid) => {}}
                />
                    
                </div>

            </div>

            <Footer/>
        </div>
    )
}

export default ResetPassword;