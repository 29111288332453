/*
This file displays the service page with subrouting to itemintro

Created by Mingke Deng, and Hans Wang
Last Modified: 25/09/2022
*/


/*
  ACCOUNTS FOR TESTING MESSAGING: 
  User: user@gmail.com Password: user 
  Merchant: Merchant@gmail.com Password: merchant 


*/

import React from 'react';
import { Outlet } from 'react-router-dom';
import '../../components/footer/Footer.css'

import Navbar from '../Home/homes/Navbar';
import NewFooter from '../../components/footer/Footer';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';


function Result() {

  return (
    <div className='result'>
      {/**Navbar */}
      <Navbar />
      <Outlet />
      <ToastContainer />
      <NewFooter />
    </div>
  )
}

export default Result