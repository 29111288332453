import React from "react";
import ReactStars from "react-rating-stars-component";
import { render } from "react-dom";
import { useNavigate } from "react-router-dom";

//components and css
import "./Event_Listing_Card.css";
import "../ImageSlider/ImageSlider";
import ImageSlider from "../ImageSlider/ImageSlider";

//vector logos
import vector_bday from "../../images/vector-icons/birthday-cake.png";
import vector_conference from "../../images/vector-icons/conference.png";
import vector_venue from "../../images/vector-icons/shop.png";
import vector_standing from "../../images/vector-icons/people.png";
import vector_seated from "../../images/vector-icons/chair.png";
import vector_star from "../../images/vector-icons/star.png";
import vector_price from "../../images/vector-icons/price_tag.png";

import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../pages/ItemIntro/cartActions";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Event_Listing_Card({ title, type, description, stand, seat, rating, imagePath, services_id, price, merchantId, eventId }) {
  const history = useNavigate();
  const cartItems = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const handleClick = () => {
    console.log("clicked");
    history(`/result/${services_id}/intro`);
  };

  //testing for image slider
  const slides = [
    { url: imagePath, title: title },
    { url: "https://images-eur.cvent.com/pr53/6a03f092600d4fd7b17308d9c0fb8bf9/beedfc5e5aa6bb934a4c6bccef6608ea/7fd7380177ad4c92a0916df31d1e07e0/9e7730605ddb469c14a53fa7a925f670!_!5efa45f3e4ce3801b26349988b74b909.jpeg?f=webp", title: "image2" },
    { url: "https://tableart.net.au/wp-content/uploads/2019/08/Whisper-Weave-33.jpg", title: "image3" },

    // { url: "https://pressspacetojump.com/wp-content/uploads/2022/10/Feature-Pokemon-Scarlet-Violet-Best-Nature-Gengar.jpeg", title: "Gengar" },
    // { url: "https://media.tenor.com/3ZncGth-40gAAAAC/poliwhirl-sad.gif", title: "Poliwhirl" },
    // { url: "https://images.theconversation.com/files/513235/original/file-20230302-17-g106nh.jpg?ixlib=rb-1.1.0&rect=20%2C54%2C1897%2C948&q=45&auto=format&w=1356&h=668&fit=crop", title: "Pikachu" },
    // { url: "https://static.fandomspot.com/images/03/12933/00-featured-gyarados-anime-screenshot.jpg", title: "Gyarados" },
    // { url: "https://staticg.sportskeeda.com/editor/2022/04/9b286-16496182209559-1920.jpg", title: "Mudkip" },
  ];

  //star rating component
  //https://www.npmjs.com/package/react-rating-stars-component
  const star_rating = {
    size: 28,
    value: rating,
    edit: false,
    isHalf: true,
  };

  const handleAddToCart = () => {
    const cartItem = {
      serviceName: title,
      type: type,
      description: description,
      standing: stand,
      seated: seat,
      rating: rating,
      imagePath: imagePath,
      services_id: services_id,
      price: price,
      merchantId: merchantId,
      eventId: eventId,
    };
    dispatch(addToCart(cartItem));
    console.log(cartItem);
    alert(`Added ${title} to cart successfully`);
  };

  return (
    <div className="event-list-card">
      <div id="event-list-card-title">
        {type == "birthday" ? <img id="eventtype-logo" src={vector_bday} /> : null}
        {type == "conference" ? <img id="eventtype-logo" src={vector_conference} /> : null}
        {type == "venue" ? <img id="eventtype-logo" src={vector_venue} /> : null}
        <p>{title}</p>
      </div>
      <div id="event-list-card-image-preview">
        <div id="image-container">
          <ImageSlider slides={slides} parentWidth={425} />
        </div>
      </div>
      <div id="event-list-card-capacity">
        <img id="event-standing-capacity" src={vector_standing} />
        {stand != null ? <p>{stand}</p> : <p>00</p>}
        <img id="event-seating-capacity" src={vector_seated} />
        {seat != null ? <p>{seat}</p> : <p>00</p>}
        {/* <img id="event-seating-capacity" src={vector_price} />
        {seat != null ? <p>{price}</p> : <p>00</p>} */}
        <div id="event-list-card-star-rating">
          <ReactStars {...star_rating} />
        </div>
        <div></div>
      </div>
      <div id="event-list-card-description">
        <h1>About {title}</h1>
        {description != null ? <p>{description}</p> : <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud</p>}
      </div>
      <div id="event-list-card-buttons">
        <button id="event-list-card-select-btn" onClick={handleAddToCart}>
          Add to Cart
        </button>
        <button id="event-list-card-view-more-btn" onClick={handleClick}>
          View More
        </button>
      </div>
    </div>
  );
}

export default Event_Listing_Card;
