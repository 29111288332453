const initState={
    modalMessage:null
}

export default(state=initState,action)=>{
    const{type,data}=action; 
    switch(type){
        case 'show':
            return {...state,modalMessage:data}
        case 'hide':
            return {...state,modalMessage:null}
        default:
            return initState
    }
}