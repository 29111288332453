import { useState, useEffect, useRef, useCallback } from "react";
import "../ImageSlider/ImageSlider.css";

const slideStyles = {
  width: "100%",
  height: "100%",
  borderRadius: "7px",
  backgroundSize: "cover",
  backgroundPosition: "center",
};

const defaultStyles = {
  display: "flex",
  height: "100%",
  transition: "transform ease-out 0.5s",
};

const slidesContainerOverflowStyles = {
  overflow: "hidden",
  height: "100%",
};

const ImageSlider = ({ slides, parentWidth, slidesContainerStyles=defaultStyles}) => {
  const timerRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };
  const goToNext = useCallback(() => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  }, [currentIndex, slides]);
  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };
  const getSlideStylesWithBackground = (slideIndex) => ({
    ...slideStyles,
    backgroundImage: `url(${slides[slideIndex].url})`,
    width: `${parentWidth}px`,
  });
  const getSlidesContainerStylesWithWidth = () => ({
    ...slidesContainerStyles,
    width: parentWidth * slides.length,
    transform: `translateX(${-(currentIndex * parentWidth)}px)`,
  });

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      goToNext();
    }, 5000);

    return () => clearTimeout(timerRef.current);
  }, [goToNext]);

  return (
    <div id="sliderStyles">
      <div>
        <div onClick={goToPrevious} id="leftArrowStyles">
          ❰
        </div>
        <div onClick={goToNext} id="rightArrowStyles">
          ❱
        </div>
      </div>
      <div style={slidesContainerOverflowStyles}>
        <div style={getSlidesContainerStylesWithWidth()}>
          {slides.map((_, slideIndex) => (
            <div key={slideIndex} style={getSlideStylesWithBackground(slideIndex)}></div>
          ))}
        </div>
      </div>
      <div id="dotsContainerStyles">
        {slides.map((slide, slideIndex) => (
          <div id="dotStyle" key={slideIndex} onClick={() => goToSlide(slideIndex)}>
            ●
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageSlider;
