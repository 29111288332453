/*
This file register user as a customer

Created by Mingke Deng, and Hans Wang
Last Modified: 23/09/2022
*/
import React, { useState } from "react";
import axios from "axios";
import "./customer.css";
import { message, Modal } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import PasswordStrengthBar from "react-password-strength-bar";
import { usePromiseTracker } from "react-promise-tracker";
import { trackPromise } from "react-promise-tracker";
import { ThreeDots } from "react-loader-spinner";
import NavBar from "../Home/homes/Navbar";
import Footer from "../../components/footer/Footer";

function Customer() {
  const [details, setDetails] = useState({
    userName: "",
    email: "",
    password: "",
    accessNumber: 5, //5 means user
  });

  const history = useNavigate();
  const LoadingIndicator = () => {
    const { promiseInProgress } = usePromiseTracker();
    return (
      promiseInProgress && (
        <div style={{ marginLeft: "14vw" }}>
          <ThreeDots color="#00BFFF" height={80} width={80} />
        </div>
      )
    );
  };

  const {
    BlockBlobClient,
    AnonymousCredential,
  } = require("@azure/storage-blob");
  const sasKey = `?sv=2021-06-08&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2022-11-29T19:45:23Z&st=2022-09-19T12:45:23Z&spr=https&sig=yx%2FKdyP71JQtzprvrusOKa2%2BYFkC8FaG2U3PAoEw07w%3D`;
  const url = "https://easyevent.blob.core.windows.net";
  const container = "image";
  const blobUpload = (e) => {
    var blobName = buildBlobName(e.target.files[0].name);

    setDetails({ ...details, imagePath: blobName });

    var login = url + "/" + container + "/" + blobName + "?" + sasKey;
    var blockBlobClient = new BlockBlobClient(login, new AnonymousCredential());
    blockBlobClient.uploadBrowserData(e.target.files[0]).then((response) => {
      console.log(response);
    });
  };

  const buildBlobName = (name) => {
    var filename = name.substring(0, name.lastIndexOf("."));
    var ext = name.substring(name.lastIndexOf("."));
    return filename + "_" + Math.random().toString(16).slice(2) + ext;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    trackPromise(
      axios
        .post("https://eventeasyapi.com/api/user/create", details)
        .then((response) => {
          console.log(response);
          sessionStorage.setItem("id", response.data.userId);
          sessionStorage.setItem("username", response.data.userName);
          sessionStorage.setItem("access", response.data.accessNumber);
          Modal.confirm({
            //a pop up window
            icon: <CheckOutlined />,
            title: "Congradulations",
            content: "Your Identity was Identified, Welcome !!!",
            onOk: () => {
              history("/");
            },
          });
        })
        .catch((error) => {
          message.error("username or email already exist");
        })
    );
  };

  return (
    // <div class="regiBase">
    //   <div className="regiBox">
    //     <div className="left">
    //       <div className='home'>
    //         <h4><Link to="/">HOME</Link></h4>
    //       </div>
    //     </div>
    //     <div className="right">
    //       <form onSubmit={submitHandler}>
    //         <h3>Hi Customer</h3>

    //         <div class="form f-user">
    //           <div class="item">
    //             <label for="userName">Username</label>
    //             <input name="userName" type="text" placeholder="username"
    //             value={details.userName} onChange={e=>setDetails({...details,userName:e.target.value})} required/>
    //           </div>
    //           <div class="item">
    //             <label for="email">Email</label>
    //             <input name="email" type="email" placeholder="email"
    //             value={details.email} onChange={e=>setDetails({...details,email:e.target.value})} required/>
    //           </div>
    //           <div class="item">
    //             <label for="password">Password</label>
    //             <input type="password" name="password" placeholder="password"
    //             value={details.password} onChange={e=>setDetails({...details,password:e.target.value})} required/>
    //           </div>
    //           <div class="item">
    //             <PasswordStrengthBar password={details.password} style={{width:'120px',marginLeft:'1vw'}}/>
    //           </div>
    //           <div class="item">
    //             <label for="imagePath">Profile Picture</label>
    //             <input type="file" onChange={e => (blobUpload(e))} />
    //           </div>
    //         </div>
    //         <div class="sending send-user">
    //           <button type="submit" class="submit">Submit</button>
    //         </div>
    //         <p className='sign-notice'>By signing up, you agree to our communication and usage terms.
    //           Already have an account? <a><Link to="/login">Login In</Link></a>
    //         </p>
    //       </form>
    //     </div>
    //   </div>
    //   <LoadingIndicator/>
    // </div>

    <div>
      <NavBar />
      <div class="register-base">
        <h3 class="login-title">Registration</h3>
        <div class="login-card-contatiner">
          <div class="left-image"></div>
          <form onSubmit={submitHandler} class="form-conatiner">
            <h4 class="mb-3 login-title-2 text-center">Welcome</h4>
            <div class="d-flex justify-content-center">
              <div class="user-icon">
                <div>
                  <i class="fas fa-user"></i>
                </div>
                <div class="user-name">User</div>
              </div>
            </div>
            <div class="input-group mb-4">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fas fa-user fa-lg icon-gray"></i>
                </span>
              </div>
              <input name="userName" type="text" placeholder="username" aria-label="username" class="form-control" value={details.userName} onChange={e=>setDetails({...details,userName:e.target.value})} required/>
            </div>
            <div class="input-group mb-4">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fas fa-envelope fa-lg icon-gray"></i>
                </span>
              </div>
              <input name="email" type="email" placeholder="Email" class="form-control" aria-label="Email" value={details.email} onChange={e=>setDetails({...details,email:e.target.value})} required/>
            </div>
            <div class="input-group mb-4">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fas fa-key fa-lg icon-gray"></i>
                </span>
              </div>
              <input type="password" name="password" placeholder="Password" class="form-control" aria-label="password" value={details.password} onChange={e=>setDetails({...details,password:e.target.value})} required/>
              
            </div>
            <div class="input-group my-2"><div><PasswordStrengthBar password={details.password} style={{width:'120px',marginLeft:'1vw'}}/></div></div>
            <div class="input-group mb-4">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fas fa-lock fa-lg icon-gray"></i>
                </span>
              </div>
              <input
                type="password"
                class="form-control"
                placeholder="Confirm Password"
                aria-label="Confirm Password"
              ></input>
            </div>
            <div class="mb-3 d-flex align-items-center justify-content-between">
              <div>
                <input
                  type="checkbox"
                  class="form-check-input"
                  name="rememberMe"
                  value="rememberMe"
                ></input>
                <label
                  class="sub-text-link-gray form-check-label"
                  for="rememberMe"
                >
                  Remember Me
                </label>
              </div>
              <button type="submit" class="btn-primary primary-green-btn">
                Register
              </button>
            </div>
            <div class="d-flex align-items-center justify-content-between mb-3">
              <span class="sub-text-link-gray">
                Have an account? <Link to="/login">Login In</Link>
              </span>
            </div>
          </form>
        </div>
        <LoadingIndicator/>
      </div>
      <Footer />
    </div>
  );
}

export default Customer;
