import React from "react";
import "./Image_Feature_Card.css";
import { useNavigate } from "react-router-dom";

function Image_Feature_Card({ title, imageURL, body }) {
  const navigate = useNavigate();
  const handleViewMore = () => {
    navigate("/venue/normal/0/2023-07-31/2/2000");
  };

  return (
    <div className="card-container">
      <div className="image-container" style={{ backgroundColor: "#609BBF" }}>
        <img src={imageURL} />
        <div id="title-container">
          <h2>{title}</h2>
        </div>
        <h2 id="bodyText">{body}</h2>
      </div>
      <div className="side-container">
        <div className="button-container">
          <button id="viewmore-btn" onClick={handleViewMore}>
            <p class="hover-underline-animation">View More</p>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Image_Feature_Card;
