import React,{useState} from "react";
import "./Reset.css";
import Navbar from "../Home/homes/Navbar";
import Footer from "../../components/footer/Footer";
import { trackPromise } from "react-promise-tracker";
import axios from "axios";
import { message } from 'antd'
import { useEffect } from 'react';


/*
    RESET PASSWORD TESTING DETAILS
        user: userpass@gmail.com
        pass: reset or reset1

    AUTOMATED EMAIL DETAILS
        email: eventeasy@outlook.com.au
        password: EventReset
*/

function ResetPass(){
    const [email,setEmail] = useState("");
    const [userEmail, setUserEmail] = useState ({
        userEmail: '', 
    })
    const [userID, setUserID] = useState ({
        userID: '',
    })

    const verifyEmail = async () => {
        //check if email exists in database
        await axios.get(`https://eventeasyapi.com/api/User/GetUserByEmail/${email}`)
        .then(async (data) => {
            console.log(data.data);
            await setUserEmail(data.data.email)
            await setUserID(data.data.userId)
            
        }).catch(err => {
            console.log(err);
            //message.error("error: invalid email");
        }) 
        sendEmail();
    }

    const sendEmail = async () => {
        console.log(`user email: ${userEmail}`)
        console.log(`user id: ${userID}`)
        message.success("Email has been sent");
        if (userEmail.toString().toLowerCase() === email.toString().toLowerCase()){
            console.log("email found in database")
            
            //send email with token 
            await axios.get(`https://eventeasyapi.com/api/User/SendMail/${userID}`)
            .then(async (data) => {
                message.success("Email has been sent");
                
            }).catch(err => {
                console.log(err);
                //message.error("error: invalid email");
            }) 
        } else {
            console.log("email not in database")
        }
    }

    const handleEmailChange = (event) => {
        setEmail(event.target.value);  
      }

    return(
        <div >
            <Navbar/>
            <div id="resetPasswordContainer"> 
                <h2>Forgot your password? </h2>
                <p>Enter your email associated with your account and we'll send you a link to reset your password.</p>
                <div className="resForm">
                    <div id="emailInput">
                        <input 
                        type="email"
                        placeholder="Email@email.com" 
                        value={email}
                        onChange={handleEmailChange}
                        required
                       
                        />
                        <button onClick={verifyEmail}>Send</button>
                    </div>

                </div>
            </div>

            <Footer/>
        </div>
    )
}

export default ResetPass;