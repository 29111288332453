import React from "react";
import {BrowserRouter as Router, Routes, Route,Redirect} from 'react-router-dom'

//Dashboard and its sub-routing
import MainDash from "./pages/Dashboard/Entry"
import WelcomeDash from "./pages/Dashboard/welcome.jpg"
import ServiceDash from "./pages/Dashboard/Service";
import UserDash from "./pages/Dashboard/User";
import Event from "./pages/Dashboard/Event";
import Message from "./pages/Dashboard/Messages";
import UserMessages from "./pages/Dashboard/UserMessages";

//login and Register page
import LoginForm from "./pages/Login/LoginForm"
import LoginMerchant from "./pages/Login/merchantLogin"
import Register from "./pages/Register/Register";
import ResetPass from "./pages/Login/ResetPass";
import ResetPassword from './pages/Login/ResetPassword';

//home page and its sub-routing
import Home from "./pages/Home/Home";
import HomeService from "./pages/Home/HomeService";
import Venue from './pages/Venue/Venue';
import VenueSearch from './pages/Venue/VenueSearch';

//Service result related
import Result from "./pages/Result/Result";
import Intro from "./pages/ItemIntro/Intro";
import Review from "./pages/ItemIntro/Review";

//profile related
import Profile from "./pages/Profile";
import Booking from "./pages/Profile/Booking";
import BookHsitory from "./pages/Profile/BookHistory";
import MakeReview from "./pages/Profile/MakeReview";
import CancelEvent from "./pages/Profile/CancelEvent";

//Shopping Cart and checkout
import CheckOut from "./pages/CheckOut/CheckOut"
import GeoCheck from "./layout/Header/GeoCheck";
//warning 404
import NotFound from "./pages/NotFound";
import Test from "./Test/Test"

import MessageDetail from "./pages/Dashboard/MessageDetail/MessageDetail";
import BookingManagement from "./pages/Dashboard/BookingManagement/index";
import BookingManagementUser from "./pages/Dashboard/BookingManagementUser/index"; 
import ApprovedBookingUser from "./pages/Dashboard/ApprovedBookingsUser/index"

export default(
    <Router>
        <Routes>
            <Route path='/' element={<Home/>}/>
            
            <Route path='/dashboard' element={<MainDash />}>
                <Route index element={<img src={WelcomeDash} style={{height:'90vh'}}/>}/>
                <Route path='/dashboard/service' element={<ServiceDash/>}/>
                <Route path="/dashboard/member/user" element={<UserDash/>}/>
                <Route path='/dashboard/event' element={<Event/>}/>
                <Route path='/dashboard/message' element={<Message/>}/>
                <Route path='/dashboard/usermessage' element={<UserMessages/>}/>
                <Route path='/dashboard/bookingmanagement' element={<BookingManagement/>}/>
                <Route path='/dashboard/bookingmanagementuser' element={<BookingManagementUser/>}/>
                <Route path='/dashboard/approvedbookinguser' element={<ApprovedBookingUser/>}/>
            </Route>
            <Route path="/test" element={<Test/>}/>

            <Route path='/profile' element={<Profile/>}> 
                <Route index element={<Booking/>}/>
            </Route>
     
            <Route path='/profile/booking/details/:eventId' element={<BookHsitory/>}/>

            <Route path='/profile/booking/review/:eventId/:sId' element={<MakeReview/>}/>
            <Route path='/event/cancel' element={<CancelEvent/>}/>


            <Route path='/geo' element={<GeoCheck/>}/>
            <Route path='/venue/' >
                <Route path='empty/:type/:date/:guest/:budget' element={<Venue/>}/>
                <Route path='normal/:type/:date/:guest/:budget' element={<Venue/>}/>
                <Route path='name/:type/:area/:budget/:name' element={<Venue/>}/>
                <Route path='advance/:type/:date/:guest/:budget/:area/:stand/:seat' element={<Venue/>}/>
                <Route path=':search' element={<VenueSearch/>}/>
            </Route>

            <Route path='/result/' element={<Result/>}>
                <Route path=":servicesId/intro" element={<Intro/>}/>
                <Route path=':servicesId/review' element={<Review/>}/>
            </Route>

            <Route path='/message/:messageID' element={<MessageDetail/>}>
                
            </Route>

            <Route path="/checkout/:eventId" element={<CheckOut/>}></Route>
            <Route path='/service' element={<HomeService/>}/>
            <Route path='/sign-user' element={<Register/>}/>
            
            <Route path='/sign-merchant' element={<Register mode={'m'}/>}/>
           
            <Route path='/login' element={<LoginForm/>}/>
            <Route path='/login-merchant' element={<LoginMerchant/>}/>
            <Route path='reset' element={<ResetPass/>}/>
            <Route path='reset-password' element={<ResetPassword/>}/>
           
            <Route path="*"element={<NotFound/>}/>
        </Routes>
    </Router>   
)
