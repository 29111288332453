import React from "react";
import { useGeolocated } from "react-geolocated";

const Geolocation = () => {
  const { coords, isGeolocationAvailable, isGeolocationEnabled } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
  });

  return !isGeolocationAvailable ? (
    // <div>Your browser does not support Geolocation</div>
    <div></div>
  ) : !isGeolocationEnabled ? (
    // <div>Geolocation is not enabled</div>
    <div></div>
  ) : coords ? (
    <table>
      <tbody>
        <tr>
          {/* <td>latitude</td>
          <td>{coords.latitude}</td> */}
          {sessionStorage.setItem("Lat", coords.latitude)}
        </tr>
        <tr>
          {/* <td>longitude</td>
          <td>{coords.longitude}</td> */}
          {sessionStorage.setItem("Long", coords.longitude)}
        </tr>
      </tbody>
    </table>
  ) : (
    // <div>Getting the location data&hellip; </div>
    <div></div>
  );
};

export default Geolocation;
