/*
This file is the base of the profile page

Created by Mingke Deng, and Hans Wang
Last Modified: 25/09/2022
*/
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import "./profile.css";
import "./Person.css";
import Navbar from "../Home/homes/Navbar";
import { message } from "antd";
import { useCallback } from "react";

const { BlockBlobClient, AnonymousCredential } = require("@azure/storage-blob");
const sasKey = `?sv=2021-06-08&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2022-11-29T19:45:23Z&st=2022-09-19T12:45:23Z&spr=https&sig=yx%2FKdyP71JQtzprvrusOKa2%2BYFkC8FaG2U3PAoEw07w%3D`;
const url = "http://easyevent.blob.core.windows.net";
const container = "image";

export default function Profile() {
  const [bookSelect, setBookSelect] = useState(true);
  const [userInfo, setUserInfo] = useState([]);
  const history = useNavigate();
  const [profileInfo, setProfileInfo] = useState([]);
  const [editing, setEditing] = useState(false);

  const onChangePerson = () => {
    setEditing(true);
  };
  const onConfirm = () => {
    setEditing(false);
  };

  const changeSelectA = () => {
    this.setBookSelect(true);
  };
  const changeSelectB = () => {
    this.setBookSelect(false);
  };
  const uid = sessionStorage.getItem("id");
  const location = useLocation(); //get current route path
  const formRef = React.useRef();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getProfile = useCallback(async () => {
    const { data } = await axios.get(`https://eventeasyapi.com/api/user/get/${uid}`);
    setUserInfo(data);
    setProfileInfo(data);
  });

  const blobUpload = (e) => {
    console.log(e);
    var blobName = buildBlobName(e.target.files[0].name);

    axios
      .put(`https://eventeasyapi.com/api/User/Update/`, {
        userId: uid,
        imagePath: blobName,
      })
      .then((response) => {
        console.log("database upload response", response);
      })
      .catch((err) => {
        console.log("upload image error", err);
      });

    //console.log(formRef)
    var login = url + "/" + container + "/" + blobName + "?" + sasKey;
    var blockBlobClient = new BlockBlobClient(login, new AnonymousCredential());
    blockBlobClient
      .uploadBrowserData(e.target.files[0])
      .then((response) => {
        console.log("blob upload response ", response);
        history(0);
        //console.log(userInfo.imagePath)
      })
      .catch((err) => {
        console.log("Upload failed", err);
      });
  };

  const buildBlobName = (name) => {
    var filename = name.substring(0, name.lastIndexOf("."));
    var ext = name.substring(name.lastIndexOf("."));
    return filename + "_" + Math.random().toString(16).slice(2) + ext;
  };
  const onSave = () => {
    console.log(profileInfo);
    axios
      .put(`https://eventeasyapi.com/api/User/Update/`, profileInfo)
      .then((response) => {
        console.log(response);
        message.success("User Update Success");
        getProfile(); // Refresh user profile data
        setEditing(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getProfile();
  }, []);

  return (
    <div className="profile">
      <Navbar />

      <div className="container mt-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <h3 className="">User Profile</h3>
          <div className="avatar align-items-center">
            <div
              className="ava-pic"
              // style={{
              //   backgroundImage: `url("https://easyevent.blob.core.windows.net/image/${userInfo.imagePath}")`,
              // }}
            ></div>
            <div className="changeProfile ml-3">
              <h5>Change Profile Picture</h5>
              <input
                type="file"
                onChange={(e) => {
                  blobUpload(e);
                }}
              />
            </div>
          </div>
        </div>
        <div className="profile-container pt-2 pb-4 px-4">
          <form ref={formRef} onFinish={onSave}>
            {/* <form ref={formRef} onSubmit={onSave}> */}
            <div className="row">
              <div className="col-md-6 mt-4">
                <label htmlFor="firstName">First Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  placeholder="First Name"
                  readOnly={!editing}
                  value={profileInfo.firstName}
                  onChange={(e) =>
                    setProfileInfo((prevProfileInfo) => ({
                      ...prevProfileInfo,
                      firstName: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="col-md-6 mt-4">
                <label htmlFor="lastName">Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  placeholder="Last Name"
                  value={profileInfo.lastName}
                  readOnly={!editing}
                  onChange={(e) =>
                    setProfileInfo((prevProfileInfo) => ({
                      ...prevProfileInfo,
                      lastName: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="col-md-6 mt-4">
                <label htmlFor="date">Date of Birth</label>
                <input
                  type="date"
                  className="form-control"
                  id="date"
                  placeholder="DOB"
                  value={profileInfo.dob}
                  readOnly={!editing}
                  onChange={(e) =>
                    setProfileInfo((prevProfileInfo) => ({
                      ...prevProfileInfo,
                      dob: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="col-md-6 mt-4">
                <label htmlFor="sex">Gender</label>
                <select
                  className="form-control"
                  id="sex"
                  placeholder="Sex"
                  value={profileInfo.sex}
                  readOnly={!editing}
                  onChange={(e) =>
                    setProfileInfo((prevProfileInfo) => ({
                      ...prevProfileInfo,
                      sex: e.target.value,
                    }))
                  }>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
              </div>

              <div className="col-md-6 mt-4">
                <label htmlFor="email">Email Address</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Email Address"
                  value={profileInfo.email}
                  readOnly={!editing}
                  onChange={(e) =>
                    setProfileInfo((prevProfileInfo) => ({
                      ...prevProfileInfo,
                      email: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="col-md-6 mt-4">
                <label htmlFor="phoneNumber">Phone Number</label>
                <input
                  type="tel"
                  className="form-control"
                  id="phoneNumber"
                  placeholder="Phone Number"
                  value={profileInfo.phoneNumber}
                  readOnly={!editing}
                  onChange={(e) =>
                    setProfileInfo((prevProfileInfo) => ({
                      ...prevProfileInfo,
                      phoneNumber: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="col-md-6 mt-4">
                <label htmlFor="userId">User ID</label>
                <input type="number" className="form-control" id="userId" placeholder="user id" value={profileInfo.userId} readOnly={true} />
              </div>
              <div className="col-md-6 mt-4">
                <label htmlFor="userName">Username</label>
                <input
                  type="text"
                  className="form-control"
                  id="userName"
                  placeholder="username"
                  value={profileInfo.userName}
                  readOnly={!editing}
                  onChange={(e) =>
                    setProfileInfo((prevProfileInfo) => ({
                      ...prevProfileInfo,
                      userName: e.target.value,
                    }))
                  }
                />
              </div>
              {/* To be fixed in the backend */}
              <div className="col-md-8 mt-4">
                <label htmlFor="address1">Address</label>
                <input
                  type="text"
                  className="form-control"
                  id="address1"
                  placeholder="Address Line 1"
                  // value={profileInfo.address ? profileInfo.address.streetNumber : ''}
                  value={profileInfo.address}
                  readOnly={!editing}
                  onChange={(e) =>
                    setProfileInfo((prevProfileInfo) => ({
                      ...prevProfileInfo,
                      address: e.target.value,
                    }))
                  }
                  // onChange={(e) =>
                  //   setProfileInfo({
                  //     ...profileInfo,
                  //     address: {
                  //       ...profileInfo.address,
                  //       streetNumber: e.target.value,
                  //     },
                  //   })
                  // }
                />
              </div>
              <div className="col-md-4 mt-4">
                <label htmlFor="suburb">Suburb</label>
                <input
                  type="text"
                  className="form-control"
                  id="suburb"
                  placeholder="Suburb"
                  value={profileInfo.address ? profileInfo.address.suburb : ""}
                  readOnly={!editing}
                  // onChange={(e) =>
                  //   setProfileInfo({
                  //     ...profileInfo,
                  //     address: {
                  //       ...profileInfo.address,
                  //       suburb: e.target.value,
                  //     },
                  //   })
                  // }
                />
              </div>
              <div className="col-md-4 mt-4">
                <label htmlFor="city">City</label>
                <select type="text" className="form-control" id="city" placeholder="City">
                  <option>Sydney</option>
                  <option>Melbourne</option>
                  <option>Hobart</option>
                  <option>Brisbane</option>
                </select>
              </div>
              <div className="col-md-4 mt-4">
                <label htmlFor="state">State</label>
                <select type="text" className="form-control" id="state" placeholder="State">
                  <option>NSW</option>
                  <option>Tasmania</option>
                </select>
              </div>
              <div className="col-md-4 mt-4">
                <label htmlFor="postCode">Post Code</label>
                <input
                  type="number"
                  className="form-control"
                  id="postCode"
                  placeholder="Post Code"
                  value={profileInfo.address ? profileInfo.address.postCode : ""}
                  readOnly={!editing}
                  onChange={(e) =>
                    setProfileInfo({
                      ...profileInfo,
                      address: {
                        ...profileInfo.address,
                        postCode: parseInt(e.target.value),
                      },
                    })
                  }
                />
              </div>
            </div>
          </form>
        </div>
        <div className="d-flex justify-content-end buttons">
          {editing ? (
            <button
              className="primary-green-btn my-4"
              onClick={() => {
                onSave();
                setEditing(false);
              }}>
              Confirm
            </button>
          ) : (
            <button
              className="primary-green-btn my-4"
              onClick={() => {
                setEditing(true);
              }}>
              Edit
            </button>
          )}
        </div>
      </div>

      <div className="navies d-none">
        <div className="nav-sub" style={{ borderBottom: bookSelect ? "3px solid #33A1C9" : "" }}>
          <Link onClick={changeSelectA} to="/profile">
            Booking History
          </Link>
        </div>
      </div>
      <div className="d-none">
        <Outlet />
      </div>
    </div>
  );
}
