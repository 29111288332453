import React from "react";
import { Modal, message } from "antd";
import "./messaging_component.css";
import axios from "axios";
import { useState } from "react";

function Messaging_component({ messageID, messageDate, messageTitle, messageQuestion, senderID, receiverID, serviceTitle }) {
  const deleteMessage = () => {
    return () => {
      Modal.confirm({
        title: "Warning",
        content: "Are you sure you want to delete this message?",
        onOk: () => {
          axios
            .delete(`https://eventeasyapi.com/api/Message/DeleteMessage/${messageID}`)
            .then((data) => {
              console.log(data);
              window.location.href = `/dashboard/message`;
            })
            .catch((err) => {
              console.log(err);
              message.error("Deletion Failed");
            });
        },
      });
    };
  };

  const [message, setMessage] = useState({
    messageID: messageID,
    messageTitle: messageTitle,
    messageQuestion: messageQuestion,
    senderID: senderID,
    recieverID: receiverID,
    messageDate: messageDate,
    messageResponse: "",
    serviceTitle: serviceTitle,
  });

  const updateMessage = async () => {
    console.log("button clicked");
    await axios
      .put(`https://eventeasyapi.com/api/Message/Update`, message)
      .then((response) => {
        console.log(response);
        console.log("success sending mesage");
      })
      .catch((err) => {
        if (err.response.status == 409) console.log("error sending message");
      });
    window.location.href = `/dashboard/message`;
  };

  return (
    <div id="message-container">
      <div id="message-box">
        <h1>{messageTitle}</h1>
        <input type="title" id="message-inbox-title" value={messageQuestion} readonly />
        <textarea id="message-inbox-content" draggable="false" placeholder="Send a reply" value={message.messageResponse} onChange={(e) => setMessage({ ...message, messageResponse: e.target.value })} />
        <div id="message-box-metadata">
          <p>messageID: {messageID}</p>
          <p>senderID: {senderID}</p>
          <p>date: {messageDate}</p>
        </div>
        <div id="message-box-buttons">
          <input type="button" class="button" value="Reply" id="message-box-send-button" onClick={() => updateMessage()} />
          <input type="button" class="button" value="Delete" id="message-box-delete-button" onClick={deleteMessage()} />
        </div>
      </div>
    </div>
  );
}

export default Messaging_component;
