/*
This is the file that display all messages
between the site user and a merchant
*/

import React, { Component } from "react";
import { Button, Card, Table, Space, Modal, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import axios from "axios";
import { usePromiseTracker } from "react-promise-tracker";
import { Puff } from "react-loader-spinner";
import { trackPromise } from "react-promise-tracker";
import { useNavigate } from "react-router-dom";

class UserMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
    };
  }

  LoadingIndicator = () => {
    const { promiseInProgress } = usePromiseTracker();
    //declare a usePromiseTracker and waiting for promise running
    return (
      //this is saying, when promise is working when return all element after &&
      promiseInProgress && (
        <div style={{ marginLeft: "14vw" }}>
          <Puff color="#00BFFF" height={80} width={800} />
        </div>
      )
    );
  };
  componentDidMount() {
    this.onGetServices(); //fetching users once upon the element are rendered
  }
  onGetServices = () => {
    if (sessionStorage.getItem("access") == 5) {
      trackPromise(
        axios.get(`https://eventeasyapi.com/api/Message/GetMessageBySenderID/${sessionStorage.getItem("id")}`).then((data) => {
          console.log(data);
          this.setState({ dataSource: data.data.$values });
        })
      );
    }
  };

  onDetail = (record) => {
    return () => {
      console.log("details", record);
      sessionStorage.setItem("MessageID", record.messageID);
      window.location.href = `/message/${record.messageID}/`;
      this.props.dispatch({
        type: "showMessage",
        data: {
          title: "Details",
          data: record,
          //passing service list so as to be used later
        },
      });
    };
  };

  onDelete = (record) => {
    return () => {
      Modal.confirm({
        //a pop up window
        title: "Warning",
        content: "Are you sure to delete this Message?",
        onOk: () => {
          axios
            .delete(`https://eventeasyapi.com/api/Message/DeleteMessage/${record.messageID}`)
            .then((data) => {
              console.log(data);
              message.success("Deletion Success");
              this.onGetServices(); //reloading
            })
            .catch((err) => {
              console.log(err);
              message.error("Deletion Failed");
            });
        },
      });
    };
  };

  getTableProps = () => ({
    pagination: {
      pageSize: 7,
      showTotal: () => `Total: ${this.state.dataSource.length} messages`,
    },
    columns: [
      {
        title: "Date",
        dataIndex: "messageDate",
        sorter: (a, b) => a.messageDate.localeCompare(b.messageDate),
      },
      {
        title: "Service name",
        dataIndex: "serviceTitle",
      },
      {
        title: "Question",
        dataIndex: "messageQuestion",
      },
      {
        title: "Message Reponse",
        dataIndex: "messageResponse",
      },

      {
        title: "Operate",
        render: (record) => (
          <Space>
            <a onClick={this.onDelete(record)}>Delete</a>
          </Space>
        ),
      },
    ],
    dataSource: this.state.dataSource || [],
  });

  render() {
    const { messageModal } = this.props.messageState;
    return (
      //this.props.isLogged == true

      <div>
        <div className="m-operate">{/** <Button type='primary' icon={<PlusOutlined/>} onClick={this.onCreate()}>ADD</Button> */}</div>
        <Card>
          {/**component */}
          <Table {...this.getTableProps()} />
          <this.LoadingIndicator />
        </Card>
      </div>
    );
  }
}

//these are fixed code for building redux
const mapStateToProps = (store) => ({ messageState: store.message });
const mapDispatchToProps = (dispatch) => ({ dispatch });
export default connect(mapStateToProps, mapDispatchToProps)(UserMessage);
