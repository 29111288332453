/*
This file is responsible for a fuzzy search
*/
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { usePromiseTracker } from "react-promise-tracker";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import './VenueSearch.css'
import Navbar from "../Home/homes/Navbar";
import Footer from "../../components/footer/Footer";
import Event_Listing_Card from "../../components/event-listing-card/Event_Listing_Card";




export default function EventSearch(){
    
    const { search} = useParams();
    const [searchResult, setSearchResult] = useState([]); 
    const history = useNavigate();

    
    const handleGoBack = () => {
        history('/');
    };


    //function to call axios api for a fuzzy search 
    const getSearchResult = async () => {
        try {
          const response = await axios.get(`https://eventeasyapi.com/api/Services/SearchServices/(search)?searchTerm=${search}`);
          const data = response.data;
          setSearchResult(data.$values);
          console.log(searchResult);
        
        } catch (error) {
          console.error(error);
          
        }
      };
      
      useEffect(() => {
        getSearchResult();
      }, []);

    return (
        <div>
            <Navbar/>
            <button id="go-back-btn" onClick={handleGoBack}>
            <FontAwesomeIcon icon={faArrowLeft} /> Go Back
            </button>
            <div>
                <h1 id='search-result-text'>Search Results for '{search}'</h1>
                
                <div className="events-search">
                {
                    searchResult.map((ele) => {
                        return (
                            <Event_Listing_Card
                            title={ele.serviceName}
                            type="conference"
                            stand={ele.standing}
                            seat={ele.seated}
                            rating={ele.rate == null || ele.rate == 0 ? "N/A" : ele.rate}
                            description={ele.description}
                            imagePath={ele.imagePath}
                            services_id={ele.servicesId}
                            price={ele.price}
                            merchantId={ele.merchantId}
                            eventId={ele.servicesId}
                          />
                        )
                    })
                }
                </div>
            </div>
            <Footer />
        </div>
    )
}