/*
This file shows the reviews recieved for a service

Created by Mingke Deng, and Hans Wang
Last Modified: 25/09/2022
*/

import React, { useState, useEffect } from "react";
import "./Review.css";
import { Tag, Rate, Checkbox, Progress, Button, Modal, Input } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Event_Listing_Card from "../../components/event-listing-card/Event_Listing_Card";
import ImageSlider from "../../components/ImageSlider/ImageSlider";
import { toast } from "react-toastify";
import { Link } from "react-router-dom/dist";
const { TextArea } = Input;

const ReviewItem = function ({ data }) {
  return (
    <div className="reviewItem">
      <div className="left">
        <div className="avatar">
          <img width={48} src={"https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"} />
        </div>
        <div>
          <p>{data.review.anonymous? "anonymous" : (data.userName || 'Anonymous')}</p>
          <p style={{fontSize: 'medium'}}>{data.review.date && data.review.date.split('T')[0]}</p>
        </div>
      </div>
      <div className="right">
        <h4 style={{marginBottom: '0'}}>{data.review.title}</h4>
        <div style={{display: 'flex'}}>
          <Rate disabled value={data.review.rate} />
        </div>
        <div>
          <p style={{fontSize: 'normal'}}>{data.review.description}</p>
        </div>
      </div>
    </div>
  );
};

const sliderStyle = {
  width: '750px',
  height: '420px',
  margin: "0 auto"
}

export default function Review() {
  const [reviewList, setReviewList] = useState([]);
  const { servicesId } = useParams();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [rate, setRate] = useState(0);
  const userInfo = {
    id: sessionStorage.getItem("id"),
    username: sessionStorage.getItem('username'),
    access: sessionStorage.getItem('access')
  }
  const history = useNavigate();
  const [reviewInfo, setReviewInfo] = useState({
    imagePath: [],
  });

  const [percentages, setPercentages] =  useState([0, 0, 0, 0, 0])

  const [showEditor, setShowEditor] = useState(false);

  const handleCancel = () => {
    setShowEditor(false);
  };

  const handleAdd = async() => {
    // check if the user is logged in
    if (userInfo.id === null) {
      setShowEditor(false);
      return
    }

    setShowEditor(false);
    // send new review request
    await axios.post(`https://eventeasyapi.com/api/Reviews/PostReview`, {
      ServicesId: servicesId,
      Title: title,
      Description: description,
      Rate: rate,
      UserId: userInfo.id,
      Date: new Date().toISOString().split("T")[0],
    }).then((response) => {
      toast.success("Review added successfully!")
      getReviewInfo()
      getReviews()
    }).catch((error) => {
      console.log(error)
      toast.error("Review failed to add!")
    })
  };

  const getReviews = async () => {
    const data = await axios
      .get(
        `https://eventeasyapi.com/api/Reviews/GetReviewByServiceID2?service_id=${servicesId}`
      )
      .catch((err) => {
        console.log(err);
      });
    setReviewList(data.data.$values);

    // calculate the percentage
    const percentages = [0, 0, 0, 0, 0]
    for (const record of data.data.$values) {
      if (record.review.rate) {
        percentages[5-parseInt(record.review.rate)] += 1
      }
    }

    const n = data.data.$values.length
    for (const index in percentages) {
      percentages[index] = Math.round(percentages[index] / n * 100)
    }

    setPercentages(percentages);
  };

  const getReviewInfo = async () => {
    const { data } = await axios
      .get(`https://eventeasyapi.com/api/services/getservices/${servicesId}`)
      .catch((err) => {
        console.log(err);
      });

    setReviewInfo({
      name: data.serviceName,
      rate: parseFloat(data.rate).toPrecision(2) || 0,
      imagePath: [
        {
          url: data.imagePath,
          title: data.serviceName,
        },
      ],
    });
  };

  useEffect(() => {
    getReviews();

    getReviewInfo();
  }, []);

  return (
    <div className="review">
      <Modal
        title="Review"
        open={showEditor}
        onOk={handleAdd}
        onCancel={handleCancel}
      >
        {userInfo.id !== null ? (
          <div>
            <p>Rate</p>
            <Rate onChange={setRate} value={rate} />
            <p>Title</p>
            <Input onChange={(e) => setTitle(e.target.value)} placeholder="Title" allowClear />
            <p>Description</p>
            <TextArea onChange={(e) => setDescription(e.target.value)} placeholder="Your review" autoSize allowClear />
          </div>
        ) : (
          <div>
            <p>You must be logged in to use this feature!</p>
            <Link to='/login'>Click here to login</Link>
          </div>
        )}
      </Modal>

      <div id="result_banner_frame">
                      <img
                        id="result-banner-image"
                        src={reviewInfo.imagePath[0]?.url}
                        alt="banner image"
                      />
                      <div className="centered-text">
                        <div id="intro-title">
                          <h2>{reviewInfo.name} Reviews</h2>
                      
                        </div>
                      </div>
                      
                    </div>
                      
      
      <div id="content">
        <div id="rate">
          <div id="statics">
            <p>Star Rating System</p>
            <p>{reviewInfo.rate}</p>
            <Rate disabled allowHalf value={parseFloat(reviewInfo.rate)} />
            <p>Average rate</p>
            <div id="avgRage">
              {[
                percentages.map((data, index) => (
                  <div className="avgItem" key={index}>
                    <div className="left">
                      <Progress
                        strokeColor={"rgb(250, 219, 20)"}
                        percent={data}
                        success={{ percent: 0 }}
                      />
                    </div>
                    <div className="right">
                      <Rate disabled value={5 - index} />
                    </div>
                  </div>
                )),
              ]}
            </div>
          </div>
          <div id="images">
            <ImageSlider slides={reviewInfo.imagePath} parentWidth={405} slidesContainerStyles={sliderStyle}/>
          </div>
        </div>
        <div>
          <p id="review-title">Reviews</p>
          <div>
            {reviewList.length > 0 ? (
              reviewList.map((item, index) => (
                <ReviewItem key={item.review.reviewId} data={item} />
              ))
            ) : (
              <p style={{ paddingLeft: "16rem" }}>No review found.</p>
            )}
          </div>
          <button id="add-review-button" onClick={() => setShowEditor(true)}>
            Add review
          </button>
        </div>
        <div></div>
      </div>
    </div>
  );
}
