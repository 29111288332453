import React, { useEffect } from 'react';
import axios from "axios";
import { useState } from 'react'

import Footer from '../../../components/footer/Footer'
import Navbar from '../../Home/homes/Navbar'
import Messaging_Component from '../../../components/messaging_component/messaging_component'
import { message } from 'antd';
function MessageDetail() {
    const [messageData, setMessageData] = useState([]); 

    
    const getMessage = async () => {
        if (sessionStorage.getItem('id')){
            const {data} = await axios.get(`https://eventeasyapi.com/api/Message/GetMessages/${sessionStorage.getItem("MessageID")}`)
            setMessageData(data.$values);
            console.log(data);
            
        }
    };
    useEffect(() => { getMessage(); }, []);

  return (
    <div>
        <Navbar/>
        { 
            
            sessionStorage.getItem('id')  ? 
        
            messageData ? 
            messageData.map((ele, index) => {
                return (
                <div>
                    <Messaging_Component 
                        messageTitle={ele.messageTitle}
                        messageID={ele.messageID}
                        senderID={ele.senderID}
                        receiverID={ele.receiverID}
                        messageDate={ele.messageDate}
                        messageQuestion={ele.messageQuestion}
                        serviceTitle={ele.serviceTitle}    
                        />
                </div>
                )
            })
            :
            <div>
                There is no data
            </div>
            
            
            : 
            <div>Invalid access </div>
        }
        <Footer/>
    </div>
  )
}

export default MessageDetail