import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, message } from "antd";
import "./style/index.css";
import axios from "axios";
import { render } from "@testing-library/react";
import { usePromiseTracker } from "react-promise-tracker";
import { ThreeDots } from "react-loader-spinner";
import { trackPromise } from "react-promise-tracker";
import { useDispatch, useSelector } from "react-redux";
import { removeFromCart } from "../../pages/ItemIntro/cartActions";
import { useParams, useNavigate, Outlet } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function ShoppingCart() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [enquireModalVisible, setEnqModelVisibile] = useState (false); 
  const showModalCheck = () => {
    setIsModalVisible(true);
  };
  const CancelCheck = () => {
    setIsModalVisible(false);
  };
  const [isServiceVisible, setServiceVisible] = useState(false);
  const CancelService = () => {
    setServiceVisible(false);
  };
  const [sends, setSends] = useState({ bookingStatus: 1 });
  const [init, setInit] = useState({ bookingStatus: 0 });
  const sType = [
    "Venue",
    "Food",
    "Beverage",
    "Entertainment",
    "Florist",
    "Photographer",
    "Power",
    "Network",
    "Music",
    "Security",
    "Restroom",
    "CarPark",
    "Waiter",
    "Transport",
    "Taxi",
    "Firework",
  ];
  const cartItems = useSelector((state) => state.cart);
  const [bookingCounter, setBookingCounter] = useState(1);
  const [status, setStatus] = useState("");
  const history = useNavigate();
  const LoadingIndicator = () => {
    const { promiseInProgress } = usePromiseTracker();
    return (
      promiseInProgress && (
        <div className="loading" style={{ marginLeft: "45%" }}>
          <ThreeDots color="#00BFFF" height={30} width={80} />
        </div>
      )
    );
  };
  const sentRequest = (ele) => {
    const url = "https://www.google.com.au";
    const sbj = "You Have a New Booking";
    const msg =
      "<h4> You have a new booking.</h4 ><br/><br/> <h4>Please go to <a href=" +
      url +
      "></a> and go to 'Dashboard' to take further actions.</h4>" +
      "<br><br><h4>Kind regards.</h4><h2>EventEasy Group</h2>";
    trackPromise(
      axios
        .put(`https://eventeasyapi.com/api/event/update/${ele.eventId}`, sends)
        .then((response) => {
          CancelCheck();
          message.success("Request has been sent");
        })
        .catch((err) => {
          console.log(err);
        })
    );
  };
  const cancelEvent = (ele) => {
    trackPromise(
      axios
        .put(`https://eventeasyapi.com/api/event/update/${ele.eventId}`, init)
        .then((response) => {
          message.success("Request has been cancelled");
        })
        .catch((err) => {
          console.log(err);
        })
    );
  };

  const [eventServices, setEventServices] = useState([]);
  const [events, setEvents] = useState([]);
  const [eventBookingStatus, setEventBookingStatus] = useState(99);
  // tracking events in the cart
  const [eventInCart, setEventInCart] = useState(false);
  const dispatch = useDispatch();
  const userId = sessionStorage.getItem("id");
  
  const getEvents = async () => {
    const { data } = await axios.get(
      `https://eventeasyapi.com/api/event/geteventbyuser/${(userId)}`
    );

    setEvents(data.$values);
    // Checking if there's an event in the cart
    const cartEvent = data.$values.find(
      (event) => event.bookingStatus !== 0 && event.bookingStatus !== 7
    );

    if (cartEvent) {
      setEventInCart(true);
    }
  };
  const getEventServices = (eventId) => {
    axios
      .get(
        `https://eventeasyapi.com/api/eventservice/getservicesbyevent/${eventId}`
      )
      .then((data) => {
        setEventServices(data.data.$values);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteEvent = (e) => {
    trackPromise(
      axios
        .delete(`https://eventeasyapi.com/api/event/delete/${e.eventId}`)
        .then((res) => {
          message.success("event has been deleted");
          CancelCheck();
        })
        .catch((err) => {
          message.error("there was an error during the deletion");
        })
    );
  };

  // console.log(cartItems)
  const renderSwitch = (parameter) => {
    switch (parameter) {
      case 0:
        return "Created";
        break;
      case 1:
        return "Sent";
        break;
      case 2:
        return "Accepted";
        break;
      case 3:
        return "Rejected";
        break;
      case 4:
        return "Cancelled";
        break;
      case 5:
        return "AwaitPaid";
        break;
      case 6:
        return "Paid";
        break;
      case 7:
        return "Completed";
        break;
      default:
        break;
    }
  };
  const requestSwitch = (parameter) => {
    switch (parameter.bookingStatus) {
      case 0:
        return (
          <>
            <button
              onClick={() => {
                sentRequest(parameter);
              }}
            >
              Send Request
            </button>
            <button
              onClick={() => {
                deleteEvent(parameter);
              }}
            >
              Delete
            </button>
          </>
        );
        break; // request
      case 1:
        return (
          <button
            onClick={() => {
              cancelEvent(parameter);
            }}
          >
            Cancel
          </button>
        );
        break; // cancel
      case 2:
        return (
          <button onClick={console.log("checkout")}>
            <Link
              to={`/checkout/${parameter.eventId}`}
              style={{ color: "#ffffff" }}
            >
              Checkout
            </Link>
          </button>
        );
        break; // checkout
      case 3:
        return <p>No Action</p>;
        break; // no use
      case 4:
        return <p>No Action</p>;
        break; // no use
      case 5:
        return (
          <button onClick={console.log("checkout")}>
            <Link to="/checkout" style={{ color: "#ffffff" }}>
              Checkout
            </Link>
          </button>
        );
        break; // checkout
      case 6:
        return <p>No Action</p>;
        break; // no use
      case 7:
        return <p>No Action</p>;
        break; // no use
      case 8:
        return <p>No Action</p>;
        break; // no use
      default:
        break;
    }
  };

  const handleClick = () => {
    history(`/login`);
  };

  const onDelete = (eId, sId) => {
    axios
      .delete(`https://eventeasyapi.com/api/EventService/Remove/${eId}/${sId}`)
      .then(() => {
        message.success("service removed");
        CancelService();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getEvents();
  }, []);

  const removeItemFromCart = (item) => {
    dispatch(removeFromCart(item));
  };

  const getItemInfo = (item) =>{
    console.log(item);
  }
  // const [booking, setBooking] = useState ({})
  const [booking, setBooking] = useState({
    userID: null,
    merchantID: null,
    bookingName: "",
    startTime: "",
    endTime: "",
    price: 0,
    guests: 0,
    comment: "",
    status: 0,
  });


  const handleStartDateChange = (event) => {
    const startDate = event.target.value;
    const startTime = `${startDate}T${booking.startTime.split('T')[1]}`;
    setBooking((prevBooking) => ({
      ...prevBooking,
      startTime: startTime
    }));
  };

  const handleEndDateChange = (event) => {
    const endDate = event.target.value;
    const endTime = `${endDate}T${booking.endTime.split('T')[1]}`;
    setBooking((prevBooking) => ({
      ...prevBooking,
      endTime: endTime
    }));
  };
  const bookEvent = () => {
    console.log(`booking item event${booking}`)
    axios.post('https://eventeasyapi.com/api/BookingManagement/Create/', booking)
    .then(response => {
      console.log(response); 
      console.log('success sending message');
      setEnqModelVisibile(false);
      setStatus(renderSwitch(1));
    
      toast.success('Booking Enquiry Sent', {
        position : toast.POSITION.TOP_RIGHT,
        autoClose: 2000, 
      });
    }).catch(err => {
      console.log('error booking')
      console.log(err)
    })
    
  }


  return (
    <div
      className="nav-links"
      onClick={() => {
        getEvents();
      }}
    >
      <a onClick={showModalCheck} className="tro-item">
        Shopping Cart
      </a>

      <div className="cartSection">
        <Modal
          title="Your Cart"
          mask={false}
          width={950}
          visible={isModalVisible}
          footer={false}
          onCancel={CancelCheck}
          className="shop-list"
        >
          <LoadingIndicator />

          <div>

            {cartItems.map((item, index) => (
              <>
              <div key={index} className="basket">
                <div className="basket-product">
                  {/* Render item details */}
                  <div className="d-flex">
                    <div className="product-image">
                      <img
                        src={item.imagePath}
                        alt="image"
                        className="product-frame"
                      ></img>
                    </div>
                    <div className="product-details">
                      <h3>{item.serviceName}</h3>
                      <h4 className="total-text">
                        Standing: <span>{item.standing}</span>
                      </h4>
                      <h4 className="total-text">
                        Seated: <span>{item.seated}</span>
                      </h4>
                      <h4 className="total-text">
                        Total: <span>{item.price} AUD</span>
                      </h4>

                      {status && (
                        <div className="alert alert-info text-right" role="alert">
                          Status: {status}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-2 mb-4">
                    <button
                      className="primary-danger-outline-btn"
                      onClick={() => removeItemFromCart(item.services_id)}
                    >
                      Remove
                    </button>
                    {/* <button className="btn-primary primary-green-btn">
      Checkout
    </button> */}
                    <button
                      className="btn-primary primary-green-btn"
                      // onClick={() => bookEvent(item)} // Call the bookEvent function here
                      onClick={() => {
                        getItemInfo(item)
                        console.log(item.merchantId)
                        setBooking({
                          userID: parseInt(sessionStorage.getItem('id')), 
                          merchantID: item.merchantId, 
                          bookingName: '', 
                          startTime: '2023-09-02T00:00:00', 
                          endTime: '2023-09-02T00:00:00', 
                          price: item.price, 
                          guests: 0, 
                          comment: '', 
                          declineReason: '', 
                          status: 0
                        })
                        setEnqModelVisibile(true)
                        
                      }}
                    >
                      Book
                    </button>

                    {/* <Link
      to={`/checkout/${item.eventId}`} // Pass the eventId as a parameter
      style={{ color: "#ffffff" }}
    >
      <button className="btn-primary primary-green-btn">
        Checkout
      </button>
    </Link> */}

                  </div>
                </div>
              </div>
              <Modal className='modalBookEvent'
                title='Enquire About Service or Event'
                visible={enquireModalVisible}
                onCancel={() => setEnqModelVisibile(false)}
                onOk={()=>bookEvent()}
                okText={sessionStorage.getItem('id') ? "Send Enquiry" : "Ok"}>
                  {sessionStorage.getItem('id') ?
                    <div id="modal-booking-container">

                      <label>Event or Service Name</label>
                      <input id=""  value={item.serviceName} readOnly />
                     
                      

                      <label>Your ID</label>
                      <input id="" type="number" value={sessionStorage.getItem('id')} readOnly />
                      <label>Start Date</label>
                      <input
                        type="date"
                        id="startDate"
                        name="startDate"
                        value={booking.startTime.split('T')[0]}
                        onChange={handleStartDateChange} />
                      {console.log(`starting date ${booking.startTime}`)
                      }
                      { 
                      
                      }

                      <label>End Date</label>
                      <input
                        type="date"
                        id="endDate"
                        name="endDate"
                        value={booking.endTime.split('T')[0]}
                        onChange={handleEndDateChange} />
                      {console.log(`starting date ${booking.endTime}`)}



                      <label>No. of Guests </label>
                      <input type="number" id="" name="" min="1" value={booking.guests} onChange={(e) => setBooking({ ...booking, guests: e.target.value })} />
                      {console.log(`booking guests ${booking.guests}`)}
                      <label>Comment or Note</label>
                      <textarea id='' for="" placeholder='Add your description' value={booking.comment} onChange={(e) => setBooking({ ...booking, comment: e.target.value, bookingName: item.serviceName, merchantID: item.merchantID})}></textarea>
                      {console.log(`booking comment ${booking.comment}`)}
                    </div>
                    :
                    <div>
                      <p>You must be logged in to use this feature!</p>
                      <a onClick={handleClick}>Click here to login</a>
                    </div>}

                </Modal></>
            ))}
          </div>

          {/* old code start */}
          <ul className="d-none">
            {events.map((ele, index) => {
              if (ele.bookingStatus <= 6) {
                return (
                  <li>
                    <div
                      className="avatar"
                      onClick={() => {
                        setEventBookingStatus(ele.bookingStatus);
                        getEventServices(ele.eventId);
                        setServiceVisible(true);
                      }}
                    >
                      Services
                    </div>
                    <div className="left">
                      <h3
                        onClick={() => {
                          setEventBookingStatus(ele.bookingStatus);
                          getEventServices(ele.eventId);
                          setServiceVisible(true);
                        }}
                      >
                        {ele.eventName}
                      </h3>
                      {/* <h5>{ele.staff}</h5> */}
                      <h4
                        style={{
                          color: ele.bookingStatus == 2 ? "green" : "red",
                        }}
                      >
                        {renderSwitch(ele.bookingStatus)}
                      </h4>
                    </div>
                    <div className="right">{requestSwitch(ele)}</div>
                  </li>
                );
              }
            })}
          </ul>
        </Modal>

        <Modal
          title="Services"
          width={850}
          visible={isServiceVisible}
          footer={false}
          onCancel={CancelService}
          className="service-list"
        >
          <ul>
            {eventServices.length !== 0 ? (
              eventServices.map((ele, index) => {
                return (
                  <li>
                    <div
                      className="avatar"
                      style={{
                        backgroundImage: `url(${ele.services.imagePath})`,
                      }}
                    ></div>
                    {console.log(ele)}
                    <div className="left">
                      <h3>{ele.services.serviceName}</h3>
                      <h4>{sType[ele.services.serviceType]}</h4>
                      <h4>Price: ${ele.services.price}</h4>
                    </div>
                    {eventBookingStatus == 0 ? (
                      <div className="right">
                        <button
                          onClick={() => {
                            onDelete(ele.eventId, ele.servicesId);
                          }}
                        >
                          Remove
                        </button>
                      </div>
                    ) : (
                      // <div className='right'>
                      //   <button onClick={() => { onDelete(ele.eventId, ele.servicesId) }}>Remove</button>
                      // </div>
                      console.log(eventBookingStatus)
                    )}
                  </li>
                );
              })
            ) : (
              // 请在这里写一个path, 引导用户到service列表
              <li className="addService">
                <div>
                  <h2>You have no services in your cart</h2>
                  <br />
                </div>
                <div>
                  <Link to="/venue/empty/0/0/0/999999">
                    Click here to browse services
                  </Link>
                </div>
              </li>
            )}
          </ul>
          {/* old code end */}
        </Modal>


  


      </div>
    </div>
  );
}
