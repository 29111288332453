/*
This file displays the service details. 
Hardcoded for now. 

Created by Mingke Deng, and Hans Wang
Last Modified: 25/09/2022
*/
import React, { useState, useEffect } from "react";
// import "./Intro.css";
import { Modal } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { FormControl } from '@mui/material';
import ReactStars from "react-rating-stars-component";
import ImageSlider from '../../components/ImageSlider/ImageSlider';
import { useDispatch } from 'react-redux';
import { addToCart } from './cartActions';
import { toast } from 'react-toastify';
import Geolocation from "../../components/geo-location/GeoLocation";
import Carousel from "../../components/carousel/carousel"


import vector_standing from '../../images/vector-icons/people.png';
import vector_seated from '../../images/vector-icons/chair.png'; 
import vector_conact from '../../images/vector-icons/chat.png'; 
import vector_review from '../../images/vector-icons/feedback.png'; 
import vector_cart from '../../images/vector-icons/add-to-cart.png'; 
import vector_accomodation from '../../images/vector-icons/bed.png';
import vector_wifi from '../../images/vector-icons/wi-fi.png';
import vector_gym from '../../images/vector-icons/dumbbell.png';
import vector_restaurant from '../../images/vector-icons/restaurant.png';
import vector_parking from '../../images/vector-icons/parking.png';
import vector_pool from '../../images/vector-icons/swimmer.png';
import { Link } from "react-router-dom/dist";
import { stringify } from "rc-field-form/es/useWatch";

import './Result2.css';
import Event_Listing_Card from "../../components/event-listing-card/Event_Listing_Card";

var star_rating = {
  size: 28,
  value: 0,
  edit: false,
  isHalf: true,
};

const containerStyles = {
  width: "1400px", // 750px + 50% = 1125px
  height: "500px", // 420px + 50% = 630px
  margin: "0 auto",
};


var slides = [
  { url: "", title: "First Image" },
  { url: "https://images-eur.cvent.com/pr53/6a03f092600d4fd7b17308d9c0fb8bf9/beedfc5e5aa6bb934a4c6bccef6608ea/7fd7380177ad4c92a0916df31d1e07e0/9e7730605ddb469c14a53fa7a925f670!_!5efa45f3e4ce3801b26349988b74b909.jpeg?f=webp", title: "image2" },
  { url : "https://tableart.net.au/wp-content/uploads/2019/08/Whisper-Weave-33.jpg", title: "image3"},
  // { url: "https://pressspacetojump.com/wp-content/uploads/2022/10/Feature-Pokemon-Scarlet-Violet-Best-Nature-Gengar.jpeg", title: "Gengar" },
  // { url: "https://media.tenor.com/3ZncGth-40gAAAAC/poliwhirl-sad.gif", title: "Poliwhirl" },
  // { url: "https://images.theconversation.com/files/513235/original/file-20230302-17-g106nh.jpg?ixlib=rb-1.1.0&rect=20%2C54%2C1897%2C948&q=45&auto=format&w=1356&h=668&fit=crop", title: "Pikachu" },
  // { url: "https://static.fandomspot.com/images/03/12933/00-featured-gyarados-anime-screenshot.jpg", title: "Gyarados" },
  // { url: "https://staticg.sportskeeda.com/editor/2022/04/9b286-16496182209559-1920.jpg", title: "Mudkip" },
];

export default function Intro() {
  const [reviewOrSale, setRS] = useState(true);
  const history = useNavigate();
  const [record, setRecord] = useState([]);
  const { servicesId } = useParams();
  const [eventVisible, setEventVisible] = useState(false);
  const [avail, setAvail] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const CancelVisible = () => { setEventVisible(false); }
  const [newService, setNewService] = useState({ eventId: 0, servicesId: servicesId, isReviewed: false });
  //const [events, setEvents] = useState([]);
  const[modalVisible, setModelVisible] = useState(false); 

  const dispatch = useDispatch();

  const sendMessage = () => {
    //if the user is not logged in 
    if (!sessionStorage.getItem('id')){
      setModelVisible(false);  
    } else {
      console.log('message: ', message);
      setModelVisible(false); 
      axios.post('https://eventeasyapi.com/api/Message/Create/', message)
      .then(response => {
        console.log(response); 
        console.log('success sending message');
      }).catch(err => {
        if ((err.response.status) === 409)
          console.log('error sending message');
      })
    }
  }

  const [message, setMessage] = useState ({
    messageTitle: '', 
    messageQuestion: '', 
    senderID: parseInt(sessionStorage.getItem('id')),
    recieverID: '',
    messageDate: '', 
    messageResponse: '', 
    serviceTitle: '',
  })

  const handleAddToCart = (ele) => {
    const itemToAdd = { 
      serviceId: servicesId, 
      serviceName: ele.serviceName, 
      imagePath: ele.imagePath, 
      price: ele.price,
      seated: ele.seated,
      standing: ele.standing,
      merchantID: ele.merchantId
     };
    dispatch(addToCart(itemToAdd));
    toast.success('Added to cart', {
      position : toast.POSITION.TOP_RIGHT,
      autoClose: 2000, 
    });
  }


  const onSave = (value) => {
    let dateNow = new Date().toISOString().split("T")[0]
    console.log(value.startTime.toISOString().split("T")[0], dateNow);
    if (value.startTime.toISOString().split("T")[0] > dateNow &&
      value.endTime.toISOString().split("T")[0] > dateNow) {
      if (value.guest > 0) {
        axios.post("https://eventeasyapi.com/api/event/create", value)
          .then(response => {
            console.log(response);
            message.success("Event created successfully");
            CancelVisible();
            history(0);
          }).catch(err => {
            console.log(err)
          })
        getData();
      } else {
        message.error("please enter a valid guest number")
      }
    } else {
      message.error("please select a valid date")
    }
  }
  const changeRS = () => { setRS(!reviewOrSale); }
  const onClick = () => {

    axios.post("https://eventeasyapi.com/api/eventService/create", newService)
      .then(response => {
        console.log(response);
        message.success("Service added successfully");
      }).catch(err => {
        if ((err.response.status) == 409)
          message.error("This service already exists.");
      })
  }

  const layout = {
    //form layout
    labelCol: { span: 10 },
    wrapperCol: { span: 250 }
  }

  const getData = async () => {
    const { data } = await axios.get(
      `https://eventeasyapi.com/api/services/getallservices`
    );
    setRecord(data.$values);
    console.log(data);
    axios
      .get(
        `https://eventeasyapi.com/api/event/getactiveeventbyuser/${sessionStorage.getItem(
          "id"
        )}`
      )
      .then((response) => {
        console.log(response);
        setAvail(response.data.$values);
      })
      .catch((err) => {
        //message.error(err, " Please Login before create an event")
      });
  };
  useEffect(() => {
    getData();
  }, []);


  /*
  RECOMENDATION
  */
 let filtered  = []; 







  return (
    <div className="intro-box">
      <Geolocation/>
      <div className="event-intro">
        {record //is there any venue information ?
          ? record.map((ele, index) => {
              //yes
              if (ele.servicesId == servicesId) {
                //only want the last venue service
                return (
                  <>
                    {/* Banner Frame*/}
                    <div id="result_banner_frame">
                      <img
                        id="result-banner-image"
                        src={ele.imagePath}
                        alt="banner image"
                      />
                      <div className="centered-text">
                        <div id="intro-title">
                          <h2>{ele.serviceName}</h2>
                          <p>{ele.serviceLocation}</p>
                          <p hidden>{(star_rating.value = ele.rate)}</p>
                          <div id="result-star-rating">
                            <ReactStars {...star_rating} />
                          </div>
                        </div>
                      </div>
                      
                    </div>
                    <div className="intro-img">
                      <p hidden> {(slides[0].url = ele.imagePath)}</p>

                      <div id="imageslider-result">
                        <div style={containerStyles}>
                          <ImageSlider slides={slides} parentWidth={1400} />
                        </div>
                      </div>

                      <div id="result-secondary-container">
                        <div id="info-container">
                          <h2>About {ele.serviceName}</h2>
                          {ele.description != null ? (
                            <p>{ele.description}</p>
                          ) : (
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor incididunt ut labore
                              et dolore magna aliqua. Elementum facilisis leo
                              vel fringilla est ullamcorper eget nulla facilisi.
                              Tortor at risus viverra adipiscing at in. Non enim
                              praesent elementum facilisis leo. Duis tristique
                              sollicitudin nibh sit amet commodo nulla facilisi
                              nullam. Ipsum a arcu cursus vitae congue mauris
                              rhoncus. Purus viverra accumsan in nisl nisi
                              scelerisque. Orci ac auctor augue mauris. Amet
                              purus gravida quis blandit turpis. Tristique
                              sollicitudin nibh sit amet commodo. Vehicula ipsum
                              a arcu cursus. Eu nisl nunc mi ipsum faucibus
                              vitae aliquet nec. Mollis nunc sed id semper.
                              Pulvinar elementum integer enim neque. Aliquet
                              sagittis id consectetur purus ut. Eu turpis
                              egestas pretium aenean pharetra. Sit amet
                              consectetur adipiscing elit ut aliquam purus. Non
                              quam lacus suspendisse faucibus interdum posuere
                              lorem ipsum dolor. Sit amet commodo nulla
                              facilisi.
                            </p>
                          )}
                        </div>
                        <div id="result-event-buttons">
                          <div id="result-standing">
                            <img src={vector_standing} />
                            {ele.standing != null ? (
                              <p>{ele.standing} Standing</p>
                            ) : (
                              <p>0 Standing</p>
                            )}
                          </div>
                          <div id="result-seated">
                            <img src={vector_seated} />
                            {ele.seated != null ? (
                              <p>{ele.seated} Seated</p>
                            ) : (
                              <p>0 Seated</p>
                            )}
                          </div>
                          <p id="result-price">Estimated Price ${ele.price}</p>
                          <div id="result-buttons">
                            <button
                              id="contact-seller-btn"
                              onClick={() => setModelVisible(true)}
                            >
                              <img src={vector_conact} />
                              Contact Seller
                            </button>
                            <button id="review-btn" onClick={() => history(`/result/${servicesId}/review`)}>
                              <img src={vector_review} />
                              Reviews
                            </button>
                            {/* <button id="add-to-cart-btn" ><img src={vector_cart}/>Add to Cart</button> */}
                            <button
                              id="add-to-cart-btn"
                              onClick={() => handleAddToCart(ele)}
                            >
                              <img src={vector_cart} alt="Add to Cart" /> Add to
                              Cart
                            </button>
                          </div>
                        </div>
                      </div>

                      <div id="result-venue-features">
                        <h2>Venue Features</h2>
                        <div id="result-venue-grid">
                          <div>
                            <img src={vector_accomodation} />
                            <p>Accomodation on-site</p>
                          </div>

                          <div>
                            <img src={vector_wifi} />
                            <p>Free Wi-Fi</p>
                          </div>

                          <div>
                            <img src={vector_gym} />
                            <p>Gym</p>
                          </div>

                          <div>
                            <img src={vector_restaurant} />
                            <p>Restaurant</p>
                          </div>

                          <div>
                            <img src={vector_parking} />
                            <p>Parking on site</p>
                          </div>

                          <div>
                            <img src={vector_pool} />
                            <p>Pool</p>
                          </div>
                        </div>
                      </div>

                      <div id="result-location">
                        <h1>Location</h1>
                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d24818.221229933322!2d147.22205417189437!3d-42.82962630198743!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xaa6e731efdc12f53%3A0x3e5d2369142e7819!2sHidden%20Garden%20Estate!5e0!3m2!1sen!2sau!4v1691533666156!5m2!1sen!2sau"
                          width="1300"
                          height="450"
                          style={{ border: 0 }}
                          allowfullscreen=""
                          loading="lazy"
                          referrerpolicy="no-referrer-when-downgrade"
                        ></iframe>
                      </div>
                      

                      <Modal
                        className="modalChat"
                        title="Contact Merchant"
                        centered
                        visible={modalVisible}
                        onCancel={() => setModelVisible(false)}
                        onOk={() => sendMessage()}
                        okText={
                          sessionStorage.getItem("id") ? "Send Question" : "Ok"
                        }
                      >
                        {sessionStorage.getItem("id") ? (
                          <div id="modal-chat-container">
                            <label id="modal-message-title">Title</label>
                            <input
                              for="message-title"
                              placeholder="Your Inquiry"
                              value={message.messageTitle}
                              onChange={(e) =>
                                setMessage({
                                  ...message,
                                  messageTitle: e.target.value,
                                  recieverID: ele.merchantId,
                                  messageDate: new Date().toLocaleDateString(),
                                  serviceTitle: ele.serviceName,
                                })
                              }
                            ></input>
                            <label id="modal-message">Message</label>
                            <textarea
                              id="modal-message-text"
                              for="message-text"
                              placeholder="Explain your queries..."
                              value={message.messageQuestion}
                              onChange={(e) =>
                                setMessage({
                                  ...message,
                                  messageQuestion: e.target.value,
                                })
                              }
                            ></textarea>
                          </div>
                        ) : (
                          <div>
                            <p>You must be logged in to use this feature!</p>
                            <Link to='/login'>Click here to login</Link>
                          </div>
                        )}
                      </Modal>
                    </div>
                  </>
                );
              } else {
                return null;
              }
            })
          : ""}{" "}
        {/**if not match then don't return */}
      
      </div>
      
          

          {
          sessionStorage.getItem('area') ?
          <div>
            <h1 id="recomendation-text"> Checkout Similar Recomendations in {sessionStorage.getItem('area')}</h1> 
            {
                          <div>
                          <div id='recomend-line'>
                          {
                            record.slice(0, 3).map((ele, index) => (
                              <Carousel
                                imagePath={ele.imagePath}
                                title={ele.serviceName}
                                description={ele.description || "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquam nulla facilisi cras fermentum odio eu feugiat pretium. Neque sodales ut etiam sit. Vitae elementum curabitur vitae nunc sed velit. Ultrices dui sapien eget mi proin sed. Ultricies mi eget mauris pharetra et ultrices. Quisque egestas diam in arcu cursus. Fermentum iaculis eu non diam phasellus vestibulum. Varius duis at consectetur lorem donec massa sapien faucibus. Mauris nunc congue nisi vitae suscipit. Faucibus vitae aliquet nec ullamcorper sit amet risus nullam."}
                                eventID={ele.servicesId}
                              />
                            ))
                          }
                          </div>
                        </div>
            }
            </div>
            : sessionStorage.getItem('city') ?
            <div>
              <h1 id="recomendation-text">Checkout Similar Recommendations in {sessionStorage.getItem('city')}</h1>
              <div id='recomend-line'>
              {
                record.slice(0, 3).map((ele, index) => (
                  <Carousel
                    imagePath={ele.imagePath}
                    title={ele.serviceName}
                    description={ele.description || "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquam nulla facilisi cras fermentum odio eu feugiat pretium. Neque sodales ut etiam sit. Vitae elementum curabitur vitae nunc sed velit. Ultrices dui sapien eget mi proin sed. Ultricies mi eget mauris pharetra et ultrices. Quisque egestas diam in arcu cursus. Fermentum iaculis eu non diam phasellus vestibulum. Varius duis at consectetur lorem donec massa sapien faucibus. Mauris nunc congue nisi vitae suscipit. Faucibus vitae aliquet nec ullamcorper sit amet risus nullam."}
                    eventID={ele.servicesId}
                  />
                ))
              }
              </div>
            </div>
            :  
            <p></p>
           }
           
          
    </div>
  );
}
