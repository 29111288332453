/*
This file shows some successful events examples

Created by Mingke Deng, and Hans Wang
Last Modified: 23/09/2022
*/
import React, { useState } from 'react';
import './HomeService.css';
import NavBar from './homes/Navbar';
// import Footer from './homes/Footer';
import {Link} from 'react-router-dom';
import NewFooter from '../../components/footer/Footer'
import '../../components/footer/Footer.css'

import eventCover from "../../images/envetCover.png"
import avatar from '../../images/avatar.png'

const event = [
  {
    id: 1,
    cover: eventCover,
    title: "Hidden Garden Esate",
    location: "125 Collinsvale Rd, Berriedale TAS 7011",
    avatar: avatar,
    name: "Mary Jane",
    description: "I bookes this event last year and it was the most stunning and beautiful venue! I book this venue during summer and it was the perfect time to capture amazing wedding photos as well!",
  },
  {
    id: 2,
    cover: eventCover,
    title: "Hidden Garden Esate",
    location: "125 Collinsvale Rd, Berriedale TAS 7011",
    avatar: avatar,
    name: "Mary Jane",
    description: "I bookes this event last year and it was the most stunning and beautiful venue! I book this venue during summer and it was the perfect time to capture amazing wedding photos as well!",
  },
];

const EventDetail = ({ data }) => {
  return (
    <div class="event-detail">
      <div class="img-wrapper">
        <img class="event-img" src={data.cover} />
      </div>
      <div class="event-info">
        <p class="event-title">{data.title}</p>
        <p class="event-location">{data.location}</p>
        <div class="user-info">
          <img class="user-avatar" src={data.avatar} />
          <p>{data.name}</p>
        </div>
        <div class="event-description">
          <p>{data.description}</p>
        </div>
        <Link to={`/eventDetail/id=${data.id}`}>
            <button>View Event details</button>
        </Link>
      </div>
    </div>
  );
};

function PastEventPage() {
  return (
    <div>
      <div id="title">Successful Events</div>
      <div id="event-list">
        {event.map((item) => (
          <EventDetail key={item.id} data={item} />
        ))}
      </div>
    </div>
  );
}

export default function HomeService(){
    return (
     <div className='ser-back'>
       <NavBar/>
       <div className='ser-content'>
        <PastEventPage/>
       </div>
       <NewFooter/>
     </div>
    )
  
}
