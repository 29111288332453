/*
This file is the navbar that appears all 
across the website

Created by Mingke Deng, and Hans Wang
Last Modified: 23/09/2022
*/
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ShoppingCart from '../../../layout/Header/ShoppingCart';
import './Navbar.css';
import { useNavigate } from "react-router-dom";
import { Drawer, Modal } from 'antd';
import { AppstoreTwoTone } from '@ant-design/icons';
import logo from "../../../images/EventEasyLogo.png"
import LoginForm from '../../Login/LoginForm';
function Navbar() {

    const history = useNavigate();
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);
    const [selectShow, setSelectShow] = useState(true);
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);
    const uid = sessionStorage.getItem('id');
    const areaOptions = ["Hobart", "SandyBay", "Kingston", "NewTown", "South Hobart", "North Hobart", "Dynnyrne"];
    const [selectedArea, setSelectedArea] = useState("");

    const handleAreaChange = (e) => {
        const selectedOption = e.target.value;
        setSelectedArea(selectedOption);
        sessionStorage.setItem("area", selectedOption);
      };

    const showButton = () => {
        if (window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };
    const [open, setOpen] = useState(false);
    const showDrawer = () => {
        setOpen(true);
      };
    
    const onClose = () => {
        setOpen(false);
      };
      
    const [isLoginFormVisible, setIsLoginFormVisible] = useState(false);

    const showLoginForm = () => {
        setIsLoginFormVisible(true);
    };

    const closeLoginForm = () => {
        setIsLoginFormVisible(false);
    };
    
    console.log(isLoginFormVisible)
    
    useEffect(() => {
        showButton();
    }, []);
    window.addEventListener('resize', showButton);
    const logUserOut = () => {
        sessionStorage.clear();
        history("/")
    }
    const setSelection = () => {
        setSelectShow(!selectShow);
        return
    }

    return (
        <div className='navbar px-3'>
            <div className='navbar-container'>
                <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
                    <img src={logo} />
                </Link>
                <div className='menu-icon' onClick={handleClick}>
                    <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                </div>
                <ul className={click ? 'nav-menu active' : 'nav-menu mb-0'}>
                    <li className='nav-item'>
                        <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                            Home
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/venue/normal/0/2023-07-31/2/2000' className='nav-links' onClick={closeMobileMenu}>
                            Book an Event
                        </Link>
                    </li>

                    {
                        sessionStorage.getItem('id')
                            ?
                            <>
                                <li className='nav-item'>
                                    <Link
                                        to='/profile'
                                        className='nav-links'
                                        onClick={closeMobileMenu}
                                    >
                                        Profile
                                    </Link>
                                </li>
                                <li className='nav-item' >
                                    <ShoppingCart />
                                </li>
                            </>
                            :
                            <li className='nav-item'>
                                <Link
                                    to='/service'
                                    className='nav-links'
                                    onClick={closeMobileMenu}
                                >
                                    Past Events
                                </Link>
                            </li>
                    }

                    {
                        sessionStorage.getItem('id')
                            ?
                            <li>
                                <Link
                                    to='/'
                                    className='nav-links'
                                    onClick={logUserOut}
                                >
                                    Logout
                                </Link>
                            </li>
                            :
                            <li>
                                {/* <Link
                                    to='/login'
                                    className='nav-links'
                                    onClick={closeMobileMenu}
                                >
                                    Login
                                </Link> */}
                                <span
                                    className='nav-links'
                                    onClick={() => {
                                        closeMobileMenu();
                                        showLoginForm();
                                    }}
                                >
                                    Login
                                </span>
                            </li>
                    }

                    {
                        sessionStorage.getItem('id')
                            ?
                            //check if logged in
                            (sessionStorage.getItem('access') < 5 || sessionStorage.getItem('access') == 5
                                ?
                                <li className='nav-item'>
                                    <Link
                                        to='/dashboard'
                                        className='nav-links'
                                        onClick={closeMobileMenu}
                                    >
                                        Dashboard
                                    </Link>
                                </li>
                                :
                                <li className='nav-item'>
                                    <Link
                                        to='/service'
                                        className='nav-links'
                                        onClick={closeMobileMenu}
                                    >
                                        Service
                                    </Link>
                                </li>

                            )

                            :
                            <li className='nav-item' onMouseEnter={setSelection} onMouseLeave={setSelection}>
                                <a className='nav-links' 
                                    style={{ display: selectShow ? '' : 'none'}}>
                                    Sign Up
                                </a>
                                <div className="register" style={{ display: selectShow ? 'none' : '' }} 
                                >

                                    <Link to='/sign-user'
                                        className=' reg-it' onClick={closeMobileMenu}
                                        style={{ display: selectShow ? 'none' : '' }}
                                    >
                                        As a User
                                    </Link>


                                    <Link to='/sign-merchant'
                                        className=' reg-it'
                                        onClick={closeMobileMenu}
                                        style={{ display: selectShow ? 'none' : '' }}
                                    >
                                        As a Merchant
                                    </Link>

                                </div>

                            </li>


                    }
                <button className='btn--outline border-0' type="nav-links" onClick={showDrawer}>
                    <AppstoreTwoTone/>
                </button>
                <Drawer title="Side Menu" placement="right" onClose={onClose} visible={open}>
                <div id="side-menu-nav">
                    <h1 className='hover-underline-animationHome'> <Link to="/venue/normal/0/2023-07-31/2/2000" style={{ color: "inherit" }} >BIRTHDAYS & PRIVATE </Link></h1>
                    <h1 className='hover-underline-animationHome'> <Link to="/venue/normal/0/2023-07-31/2/2000" style={{ color: "inherit" }} >CORPORATE FUNCTIONS </Link></h1>
                    <h1 className='hover-underline-animationHome'>  <Link to="/venue/normal/0/2023-07-31/2/2000" style={{ color: "inherit" }} >WEDDING & ENGAGEMENTS </Link></h1>
                    <h1 className='hover-underline-animationHome'> 
                        <Link to="/service" style={{ color: "inherit" }} >PAST SUCCESSFUL EVENTS‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ </Link>
                    </h1>
                    <h1 className='hover-underline-animationHome' onClick={() => {
                            closeMobileMenu();
                            showLoginForm();
                            }}>LOGIN</h1>
                </div>


               
               <h3 id="h2-side-menu"> Set Website Settings and Preferences</h3>
                <p>Set your area</p>
                <select value={selectedArea} onChange={handleAreaChange}>
                    <option value="">Select an area</option>
                    {areaOptions.map((area) => (
                    <option key={area} value={area}>
                        {area}
                    </option>
                    ))}
                 </select>

                 <svg className="editorial"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 24 150 28"
                    preserveAspectRatio="none">
                    <defs>
                        <path id="gentle-wave"
                        d="M-160 44c30 0 58-18 88-18s58 18 88 18 58-18 88-18 58 18 88 18v44h-352z" />
                    </defs>
                    <g className="parallax1">
                        <use xlinkHref="#gentle-wave" x="50" y="3" fill="#f461c1" />
                    </g>
                    <g className="parallax2">
                        <use xlinkHref="#gentle-wave" x="50" y="0" fill="#4579e2" />
                    </g>
                    <g className="parallax3">
                        <use xlinkHref="#gentle-wave" x="50" y="9" fill="#3461c1" />
                    </g>
                    <g className="parallax4">
                        <use xlinkHref="#gentle-wave" x="50" y="6" fill="#fff" />
                    </g>
                </svg>
                 
                </Drawer>
                </ul>
            </div>
            {isLoginFormVisible && (
                <div className='login-form-container'>
                    <div className='login-form-wrapper'>
                        <LoginForm onClose={closeLoginForm} />
                    </div>
                </div>
            )}
        </div>
     
    )
}

export default Navbar