import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import MailchimpSubscribe from "react-mailchimp-subscribe";

export default function Footer() {
  const url = "https://tech.us14.list-manage.com/subscribe/post?u=76ab46500752dde5a78bb6e08&amp;id=2fd2ab6120&amp;f_id=00b597e0f0";
  // simplest form (only email)
  const SimpleForm = () => <MailchimpSubscribe url={url} id="mailchimp" />;
  return (
    <div class="footer-container">
      <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css"></link>
      <div class="footer">
        <div class="footer-email-form">
          <h2>Sign Up for Our Newsletter</h2>
          <MailchimpSubscribe
            url={url}
            render={({ subscribe, status, message }) => (
              <div>
                <SimpleForm onSubmitted={(formData) => subscribe(formData)} />
                {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
                {status === "error" && <div style={{ color: "red" }} dangerouslySetInnerHTML={{ __html: message }} />}
                {status === "success" && <div style={{ color: "green" }}>Subscribed !</div>}
              </div>
            )}
          />
          <p> Sign up to receive emails for events, special tips and general info </p>
          {/* <h2>Sign Up for Our Newsletter</h2>
          <input type="email" placeholder="your@email.com" id="footer-email"></input>
          <input type="submit" value="Sign Up" id="footer-email-btn"></input>
          <p> Sign up to receive emails for events, special tips and general info </p> */}
        </div>
        <div class="footer-heading footer-1">
          <h2> About us</h2>
          <a href="#">How it works</a>
          <a href="#">Testimonials</a>
          <a href="#">Careers</a>
          <a href="#">Investors</a>
        </div>
        <div class="footer-heading footer-2">
          <h2> Contact Us</h2>
          <a href="#">1800319870</a>
          <a href="#">eventeasy@healthshack.tech</a>
          <Link to="sign-merchant">
            <p>Merchant Login</p>
          </Link>
          <Link to="/reset">Forget your password?</Link>
        </div>
        <div class="footer-heading footer-3">
          <h2> Connect</h2>
          <div class="social-links">
            <a href="#">
              <i class="fab fa-facebook-f"></i>
            </a>
            <a href="#">
              <i class="fab fa-twitter"></i>
            </a>
            <a href="#">
              <i class="fab fa-linkedin-in"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
