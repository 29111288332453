import React, { useState, Component } from "react";
import axios from "axios";
import { Modal } from "antd";
import { useNavigate, Link } from "react-router-dom";
import { CheckOutlined } from "@ant-design/icons";
import "./login.css";
import { usePromiseTracker } from "react-promise-tracker";
import { trackPromise } from "react-promise-tracker";
import { Bars, ThreeDots } from "react-loader-spinner";
import NavBar from "../Home/homes/Navbar";
import Footer from "../../components/footer/Footer";

// function merchantLogin() {
//   const [details, setDetails] = useState({
//     email: "",
//     password: "",
//     logging: false,
//   });
//   const history = useNavigate();
//   const LoadingIndicator = () => {
//     const { promiseInProgress } = usePromiseTracker();
//     return (
//       promiseInProgress && (
//         <div style={{ marginLeft: "14vw" }}>
//           <ThreeDots color="#00BFFF" height={80} width={80} />
//         </div>
//       )
//     );
//   };

//   const passwdChecking = (e) => {
//     e.preventDefault(); //avoid page re-render
//     trackPromise(
//       axios
//         .post("https://eventeasyapi.com/api/user/login/", details)
//         .then((response) => {
//           //200
//           console.log(details);
//           sessionStorage.setItem("id", response.data.userId);
//           sessionStorage.setItem("username", response.data.userName);
//           sessionStorage.setItem("access", response.data.accessNumber);
//           Modal.confirm({
//             //a pop up window
//             icon: <CheckOutlined />,
//             title: "Congratulations",
//             content: "Your Identity was Identified, Welcome !!!",
//             onOk: () => {
//               history(-1);
//             },
//           });
//           console.log(response);
//         })
//         .catch((error) => {
//           console.log(error);
//           Modal.confirm({
//             //a pop up windows
//             title: "Verification Failed",
//             content: "Your Password Is Not Correct !!!",
//           });
//         })
//     );
//   };

export default class merchantLogin extends Component {
  render() {
    return (
      <div>
        <NavBar />
        <div class="login-base">
          <h3 class="login-title">Login</h3>
          <div class="login-card-contatiner">
            <div class="left-image"></div>
            <form
              //     onSubmit={passwdChecking}
              class="form-conatiner"
            >
              <h4 class="mb-3 login-title-2">Welcome</h4>
              <div class="input-group mb-4">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">
                    <i class="fas fa-user fa-lg icon-gray"></i>
                  </span>
                </div>
                <input
                  name="email"
                  type="text"
                  placeholder="Staff ID"
                  class="form-control"
                  aria-label="Email"
                  //     value={details.email}
                  //     onChange={(e) =>
                  //       setDetails({ ...details, email: e.target.value })
                  //     }
                  //     required
                />
              </div>
              <div class="input-group mb-4">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">
                    <i class="fas fa-key fa-lg icon-gray"></i>
                  </span>
                </div>
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  class="form-control"
                  aria-label="paasword"
                  //     value={details.password}
                  //     onChange={(e) =>
                  //       setDetails({ ...details, password: e.target.value })
                  //     }
                  //     required
                />
              </div>
              <div class="mb-3 d-flex align-items-center justify-content-between">
                <div>
                  <input
                    type="checkbox"
                    class="form-check-input"
                    name="rememberMe"
                    value="rememberMe"
                  ></input>
                  <label
                    class="sub-text-link-gray form-check-label"
                    for="rememberMe"
                  >
                    Remember Me
                  </label>
                </div>
                <button type="submit" class="btn-primary primary-green-btn">
                  Login
                </button>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-3">
                <span class="sub-text-link-gray">
                  Don't have an account?{" "}
                  <Link to="/sign-merchant">Register User?</Link>
                </span>
                <a class="sub-text-link-gray" href="">
                  <Link to="/reset">forget password?</Link>
                </a>
              </div>
              {/* <LoadingIndicator /> */}
            </form>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

// export default merchantLogin;
