/*
This file allows user to checkout an event and pay

Created by Mingke Deng, and Hans Wang
Last Modified: 26/09/2022
*/

import React, { useEffect, useState } from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBRow,
} from "mdb-react-ui-kit";
import "./checkout.css";
import { useNavigate, useParams } from "react-router-dom";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import Navbar from "../Home/homes/Navbar";
import axios from "axios";
import {  Button, message, Space  } from 'antd'

function CheckOut() {
  const { eventId } = useParams();
  const [eventService, setEventService] = useState([]);
  const [event, setEvent] = useState([]);
  const eventType = ["Wedding", "Birthday", "Business Function"];
  var price = 0;
  const history = useNavigate();

  const [messageApi, contextHolder] = message.useMessage();
  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Purchase Successful',
    });
  };

  const getBooked = () => {
    axios
    .get(`https://eventeasyapi.com/api/BookingManagement/GetAcceptedBookingByBookingID?id=${eventId}`)
    .then((response) => {
      setEventService(response.data.$values)
    })
    .catch((err) => {
      console.log(err)
    })
  }
  const setToPaid = () => {
    axios
      .put(`https://eventeasyapi.com/api/BookingManagement/SetBookingToPaid/id?=${eventId}`)
      .catch((err) => {
        console.log(err)
      })
      success(); 
  };
console.log(eventService)
  useEffect(() => {
    //useEffect is a default function called same as componentDidMount
    // getServices();
    // getEvent();
    getBooked();
  }, []);
  return (
    
    <MDBContainer fluid style={{ backgroundColor: "#eee" }}>
      <Navbar />
      <MDBCard className="pt-2">
      {contextHolder}
        <MDBCardBody>
          <MDBRow className="d-flex justify-content-center pb-5">
            <MDBCol md="7" xl="5" className="mb-4 mb-md-0">
              <h2 className="text-success">PAYMENT</h2>
              <h4 className="mb-4">Order Details </h4>

              {eventService && eventService.map((ele) => {
                return (
                  <div>
                  <div className="d-flex align-items-start">
                    {/* <img
                      // src={ele.services.imagePath}
                      alt={ele.bookingName}
                      className="mr-3"
                      style={{ width: "50px", height: "50px"}}
                    /> */}
                    <div className="p-1">
                      <h4><b>{ele.bookingName}</b></h4>
                      <h5>
                        Comment: {ele.comment}
                      </h5>
                      <p> Guests: {ele.guests}</p>
                      <p>
                        <b>
                          Price{" : "}
                          <span className="text-success">
                            $
                            {ele.price != null
                              ? ele.price
                              : "N/A"}
                          </span>

                        </b>
                      </p>
                    </div>
                    
                  </div>
                  <PayPalScriptProvider
                options={{ currency: "AUD", "client-id": "AQHBWhlWFwNz5grJqJtn2gwSXInXDvnauMnpSBZLTQGA5kr7vu9c6nnX1yv0l1y3DgsllMYpShIapXpJ" }}
              >
                <PayPalButtons
                  createOrder={(data, actions) => {
                    return actions.order.create({
                      purchase_units: [
                        {
                          amount: {
                            currency_code: "AUD",
                            value: ele.price, //paypal pay total price
                          },
                        },
                      ],
                    });
                  }}
                  onApprove= {(data, actions) => {
                    const order = actions.order.capture();
                    console.log(order); 
                    console.log("order complete") 
                    setToPaid(); 
                  }
                }
                />
              </PayPalScriptProvider>
                  </div> 
                );
              })}


            </MDBCol>
            <MDBCol md="5" xl="4" offsetXl="1">
              <div
                className="rounded d-flex flex-column p-2"
                style={{ backgroundColor: "#f8f9fa" }}
              >
                <div className="p-2 me-3">
                  <h4>Order Summary</h4>
                </div>
                {eventService.map((ele, index) => {
                  price += ele.price;
                  return (
                    <div key={index} className="p-2 d-flex">
                      <MDBCol size="8">{ele.bookingName}</MDBCol>
                      <div className="ms-auto">
                        Price: $
                        {ele.price != null
                          ? ele.price
                          : "N/A"}
                      </div>
                    </div>
                  );
                })}
                <div className="border-top px-2 mx-2"></div>
                <div className="p-2 d-flex pt-3">
                  <MDBCol size="8">
                    <b>Total</b>
                  </MDBCol>
                  <div className="ms-auto">
                    <b className="text-success">${price.toFixed(2)}</b>
                  </div>
                </div>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
    </MDBContainer>
  );
}

export default CheckOut;
